import { createRoot } from "react-dom/client";
import App from "./App";
import {
  Auth0Provider,
  ProfileProvider,
  settings as auth0Settings,
} from "./auth0";
import * as Sentry from "@sentry/react";
import { settings as sentrySettings } from "./sentry";
import { AppState } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { OwnTalentProfileProvider } from "./OwnTalentProfile";

Sentry.init(sentrySettings);

const history = createBrowserHistory();

const onRedirectCallback = (appState?: AppState) => {
  history.push(appState?.returnTo ?? window.location.pathname);
};

const root = createRoot(document.getElementById("app-root") as HTMLElement);

root.render(
  <Sentry.ErrorBoundary showDialog>
    <Auth0Provider
      domain={auth0Settings.domain}
      clientId={auth0Settings.clientId}
      audience={auth0Settings.apiIdentifier}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <ProfileProvider>
        <OwnTalentProfileProvider>
          <App />
        </OwnTalentProfileProvider>
      </ProfileProvider>
    </Auth0Provider>
  </Sentry.ErrorBoundary>,
);
