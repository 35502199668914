import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getOwnTalentProfile,
  TalentProfileSelfUpdateRequest,
  RestrictedTalentProfileData,
  updateOwnTalentProfile,
  TalentProfileCreationRequest,
  createTalentProfile,
} from "./talent/profile/api";
import { useProfile } from "./auth0";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

export type TransientState =
  | "pending-payment-redirect"
  | "confirmed-payment-redirect"
  | "union-profile-redirect";

type TalentProfileData =
  | (TalentProfileSelfUpdateRequest & {
      id: string | number;
      transientState?: TransientState;
    })
  | TalentProfileCreationRequest
  | null
  | undefined;

type TalentProfileVariant =
  | (RestrictedTalentProfileData & {
      transientState?: TransientState;
    })
  | Error
  | null
  | undefined;

type TalentProfileContextType = [
  TalentProfileVariant,
  (value: TalentProfileData) => Promise<void>,
];

export const OwnTalentProfileContext = createContext<TalentProfileContextType>([
  undefined,
  () => Promise.resolve(),
]);

type TalentProfileContextOptions = {
  talentProfile?: {
    instance?: Exclude<TalentProfileVariant, undefined>;
    set?: (value: TalentProfileVariant) => void;
  };
};

export const useOwnTalentProfile: () => TalentProfileContextType = () =>
  useContext(OwnTalentProfileContext);

export function OwnTalentProfileProvider({
  children,
  talentProfile: data,
}: {
  children: ReactNode;
} & TalentProfileContextOptions): JSX.Element {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [talentProfile, setTalentProfile] = useState<TalentProfileVariant>(
    data?.instance,
  );
  const { profile } = useProfile();
  const setter = data?.set ?? setTalentProfile;

  const save = async (value: TalentProfileData): Promise<void> => {
    const token = await getAccessTokenSilently();

    // eslint-disable-next-line eqeqeq
    if (value == undefined) {
      await refreshTalentProfile();
      return;
    }
    if ("id" in value) {
      const { id, transientState: ignored, ...request } = value;

      await updateOwnTalentProfile(id, request, { token });

      await refreshTalentProfile(value?.transientState);
    } else {
      await createTalentProfile(value, {
        token,
      });

      await refreshTalentProfile();
    }
  };

  const refreshTalentProfile = useCallback(
    async (transientState?: TransientState) => {
      try {
        const token = await getAccessTokenSilently();

        const talentProfile = await getOwnTalentProfile({
          token,
        });

        if (talentProfile !== null && transientState !== undefined) {
          setter({ ...talentProfile, transientState: transientState });
        } else {
          setter(talentProfile);
        }
      } catch (err) {
        const error = err as AxiosError;
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to look up the talent profile",
            data: {
              request: error.request as unknown,
              response: error.response,
              profile,
            },
          }),
        );
        setter(error);
      }
    },
    [getAccessTokenSilently, profile, setter],
  );

  useEffect(() => {
    if (isLoading || data !== undefined) {
      return;
    }
    if (talentProfile instanceof Error || talentProfile !== undefined) {
      return;
    }
    if (!profile) {
      return;
    }
    void refreshTalentProfile();
  }, [
    getAccessTokenSilently,
    refreshTalentProfile,
    isLoading,
    talentProfile,
    profile,
    data,
    setter,
  ]);

  return (
    <OwnTalentProfileContext.Provider value={[talentProfile, save]}>
      {children}
    </OwnTalentProfileContext.Provider>
  );
}
