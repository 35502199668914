import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import NewReleasesTwoToneIcon from "@mui/icons-material/NewReleasesTwoTone";
import { FILES_CDN_ADDRESS } from "../../cdn";
import { BlogPost } from "../api";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  pinned: {
    alignSelf: "center",
    color: theme.palette.secondary.main,
  },
  title: {
    flexGrow: 1,
  },
  postContent: {
    marginTop: theme.spacing(2),
    img: {
      maxWidth: "100% !important",
    },
    table: {
      width: "100% !important",
    },
  },
  skeletonSpacing: {
    marginBottom: theme.spacing(2),
  },
  summary: {
    marginTop: theme.spacing(1),
  },
}));

export function getImageUrl(post: BlogPost): string {
  return post.featuredImageUrl
    ? post.featuredImageUrl
    : `${FILES_CDN_ADDRESS}/logo-v2.png`;
}

export default function PostContent(props: {
  post: BlogPost;
  variant: "full" | "summary";
}): JSX.Element {
  const { post, variant } = props;
  const { classes, cx } = useStyles();

  const publishedAt = post.publishedAt
    ? new Date(post.publishedAt).toLocaleDateString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour12: true,
        minute: "2-digit",
        hour: "numeric",
        hourCycle: "h12",
      })
    : undefined;

  return (
    <Box>
      <Box display="flex">
        {post.isPinned ? (
          <NewReleasesTwoToneIcon className={classes.pinned} />
        ) : null}
        <Typography
          variant={variant === "summary" ? "h5" : "h4"}
          component="p"
          className={cx(
            classes.title,
            variant === "summary" ? undefined : classes.postContent,
          )}
        >
          {variant === "full" ? (
            post.title
          ) : (
            <MuiLink
              component={Link}
              to={`/news/${post.slug}`}
              underline="hover"
            >
              {post.title}
            </MuiLink>
          )}
        </Typography>
      </Box>
      <Typography variant="caption">
        <span>{publishedAt}</span> · <span>{post.readTime}</span>
      </Typography>
      {variant === "full" ? (
        <div
          className={classes.postContent}
          dangerouslySetInnerHTML={{
            __html: post.content ?? "",
          }}
        />
      ) : (
        <Typography variant="body2" component="p" className={classes.summary}>
          {post.summary}
        </Typography>
      )}
    </Box>
  );
}
