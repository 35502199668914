import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Alert from "@mui/material/Alert";
import { AxiosError } from "axios";
import {
  ValidationProblemDetails,
  getFirstValidationError,
} from "../../../../api/validation";
import * as Sentry from "@sentry/react";
import { useAuth0 } from "../../../../auth0";
import { updateTalentProfile } from "../../api";

export type PaymentData = {
  id: number | string;
  paidAt?: string | null;
  union: boolean;
};

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
  },
  root: {
    padding: theme.spacing(2),
  },
  warning: {
    color: theme.palette.warning.main,
  },
  unionMessage: {
    marginLeft: theme.spacing(0.5),
  },
  error: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export type PaymentDataChange = {
  paidAt: string | null | undefined;
};

export default function PaymentInfoEditPanel(props: {
  paymentData: PaymentData;
  onChange?: (data: PaymentDataChange) => void | Promise<void>;
}): JSX.Element {
  const {
    paymentData: { id, paidAt, union },
    onChange,
  } = props;
  const [data, setData] = useState<PaymentDataChange>({
    paidAt,
  });
  const [error, setError] = useState<string | null>(null);
  const [busy, setBusy] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { classes } = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PaymentDataChange>({
    criteriaMode: "all",
    defaultValues: {
      paidAt: data.paidAt ?? "",
    },
  });
  const formatDate = (value: string | undefined | null) => {
    if (!value) {
      return;
    }

    return new Date(value).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const updatePaymentDate = async (data: PaymentDataChange) => {
    setBusy(true);
    try {
      const token = await getAccessTokenSilently();
      const paidAt = data.paidAt === "" ? null : data.paidAt;
      await updateTalentProfile(
        id,
        {
          paidAt: { value: paidAt },
        },
        {
          token,
        },
      );
      setError(null);
      setData({
        paidAt: data.paidAt,
      });
      await onChange?.({ paidAt });
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setError(validationError.message);
          return;
        }
      }
      setError(
        "An error when editing the talent profile payment information. Please try again...",
      );
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to edit talent profile payment data",
          data: {
            talentProfile: {
              id,
            },
            request: error.request as unknown,
            response: {
              status: error.response?.status,
              body: error.response?.data,
            },
          },
        }),
      );
    } finally {
      setBusy(false);
    }
  };

  return (
    <Paper className={classes.root}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(updatePaymentDate)}
      >
        {union ? (
          <Box className={classes.warning} display="flex">
            <WarningIcon fontSize="small" />
            <Typography variant="caption" className={classes.unionMessage}>
              This user is a union member
            </Typography>
          </Box>
        ) : null}
        <FormControl className={classes.formControl}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="Paid at"
                type="date"
                error={errors.paidAt !== undefined}
                InputLabelProps={{ shrink: true, required: false }}
                helperText={errors.paidAt?.message ?? formatDate(field.value)}
                variant="standard"
              />
            )}
            name="paidAt"
            control={control}
          />
        </FormControl>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={busy}
          className={classes.actionButton}
        >
          Save
        </Button>
      </form>
      {busy ? <LoadingIndicator /> : null}
      {error !== null ? (
        <Alert
          className={classes.error}
          onClose={() => {
            setError(null);
          }}
          severity="error"
        >
          {error}
        </Alert>
      ) : null}
    </Paper>
  );
}
