import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles()((theme) => ({
  error: {
    height: theme.spacing(8),
    color: theme.palette.error.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

export default function AccessDeniedPanel(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.root} data-testid="access-denied">
      <ErrorOutlineIcon fontSize="large" className={classes.error} />
      <Typography variant="body1">You cannot access this section</Typography>
    </div>
  );
}
