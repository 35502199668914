import { RequestOptions, settings } from "../api";
import axios from "axios";
import { paths } from "../api/schema/v1";

const contentType = "application/json; v=1.0";
const accept = "application/json;v=1";

const updatePath: keyof paths = "/user/profile/{id}";
export type UpdateRequest =
  paths[typeof updatePath]["patch"]["requestBody"]["content"][typeof contentType];

export async function updateUser(
  id: string,
  request: UpdateRequest,
  options: RequestOptions,
): Promise<void> {
  const { token } = options;
  await axios.patch(
    `${settings.url}${updatePath.replace("{id}", id)}`,
    request,
    {
      headers: {
        "Content-Type": contentType,
        Accept: accept,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );
}

const deletePath: keyof paths = "/user/profile/{id}";
export type DeleteRequest =
  paths[typeof deletePath]["delete"]["parameters"]["path"];

export async function deleteUser(
  request: DeleteRequest,
  options: RequestOptions,
): Promise<void> {
  const { token } = options;
  await axios.delete(
    `${settings.url}${deletePath.replace("{id}", request.id.toString())}`,
    {
      headers: {
        Accept: accept,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );
}
