import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAuth0 } from "../auth0";
import { getPost, PostResponse } from "./api";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { isTimeoutError } from "../api";
import ApiErrorPanel from "../components/ApiErrorPanel";
import AccessDeniedPanel from "../components/AccessDeniedPanel";
import SinglePost from "./components/Post";

export default function Post(props: { slug?: string }): JSX.Element {
  const routeParams = useParams<{ slug: string | undefined }>();
  const slug = props.slug ?? routeParams.slug;

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [post, setPost] = useState<PostResponse | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);

  const hasAccess = isAuthenticated;

  useEffect(() => {
    async function getSinglePost(slug: string) {
      try {
        const token = await getAccessTokenSilently();
        const data = await getPost({ slug }, { token });
        setPost(data);
      } catch (error) {
        if (isTimeoutError(error)) {
          setApiError(
            "Request to get the news post failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          const err = error as AxiosError;
          if (err.isAxiosError && err.response?.status === 404) {
            setApiError("Selected news post was not found");
            return;
          }
          setApiError(
            "Failed to retrieve the news post, please check again later",
          );
        }
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to get a single news post",
            data: {
              slug,
            },
          }),
        );
      }
    }

    if (!hasAccess || !slug) {
      return;
    }

    void getSinglePost(slug);
  }, [slug]);

  if (!slug) {
    return <Navigate to="/" />;
  }

  if (apiError) {
    return <ApiErrorPanel message={apiError} />;
  }

  if (!hasAccess) {
    return <AccessDeniedPanel />;
  }

  return <SinglePost post={post} />;
}
