import axios, { AxiosError, AxiosResponse } from "axios";
import {
  Paged,
  RequestOptions,
  settings,
  TOTAL_COUNT_HEADER,
} from "../../../api";
import { components, paths } from "../../../api/schema/v1";
import { tryParseInt } from "../../../parsing";

const contentType = "application/json; v=1.0";
const accept = "application/json;v=1";

const creationPath: keyof paths = "/talent/profile/{id}/note";

export type NoteCreationRequest =
  paths[typeof creationPath]["post"]["requestBody"]["content"][typeof contentType];
export type NoteCreationResponse =
  paths[typeof creationPath]["post"]["responses"]["200"]["content"][typeof accept];

export type FullNoteCreationRequest = NoteCreationRequest & {
  talentProfileId: string | number;
};

export async function createNote(
  request: FullNoteCreationRequest,
  options: RequestOptions,
): Promise<NoteCreationResponse> {
  const { token } = options;
  const { talentProfileId, ...data } = request;
  const response = await axios.post<
    NoteCreationRequest,
    AxiosResponse<NoteCreationResponse>
  >(
    `${settings.url}${creationPath.replace(
      "{id}",
      talentProfileId.toString(),
    )}`,
    data,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );

  return response.data;
}

const getSinglePath: keyof paths = "/talent/profile/note/{id}";

export type SingleNote =
  paths[typeof getSinglePath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getNote(
  request: { id: string | number },
  options: RequestOptions,
): Promise<SingleNote | null> {
  const { token } = options;

  try {
    const response = await axios.get<SingleNote>(
      `${settings.url}${getSinglePath.replace("{id}", request.id.toString())}`,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
          Accept: accept,
        },
        timeout: 10_000,
      },
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 404) {
      return null;
    }

    throw err;
  }
}

const getUserNotesPath: keyof paths = "/talent/profile/notes";

export type UserNotesRequest =
  paths[typeof getUserNotesPath]["get"]["parameters"]["query"];
export type UserNotesResponse =
  paths[typeof getUserNotesPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function listUserNotes(
  request: UserNotesRequest,
  options: RequestOptions,
): Promise<Paged<UserNotesResponse>> {
  const { token } = options;
  const response = await axios.get<UserNotesResponse>(
    `${settings.url}${getUserNotesPath}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      params: request,
      timeout: 10_000,
    },
  );

  const totalCountHeader = (
    response.headers as Record<string, string | undefined>
  )[TOTAL_COUNT_HEADER];
  return {
    data: response.data,
    totalCount: tryParseInt(totalCountHeader) ?? 0,
  };
}

export type NoteItem = components["schemas"]["TalentProfileNote"];

const deletePath: keyof paths = "/talent/profile/{id}/note";
export type NoteDeleteRequest =
  paths[typeof deletePath]["delete"]["parameters"]["path"];

export async function deleteNote(
  request: NoteDeleteRequest,
  options: RequestOptions,
): Promise<void> {
  const { token } = options;
  await axios.delete(
    `${settings.url}${deletePath.replace("{id}", request.id.toString())}`,
    {
      headers: {
        Accept: accept,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );
}
