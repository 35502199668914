import axios from "axios";
import { RequestOptions, settings } from "../api";
import { components, paths } from "../api/schema/v1";

const accept = "application/json;v=1";

export type LookupData = components["schemas"]["LookupData"];

/**
 * **Exported only for testing**
 */
export function getLookupData<T extends LookupData[]>(path: keyof paths) {
  return async function executeLookupRequest(
    options: RequestOptions,
  ): Promise<LookupData[]> {
    const { token } = options;
    const response = await axios.get<T>(`${settings.url}${path}`, {
      headers: {
        Accept: accept,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    });
    return response.data;
  };
}

const ethnicityPath: keyof paths = "/ethnicity";
export type EthnicityResponse =
  paths[typeof ethnicityPath]["get"]["responses"]["200"]["content"][typeof accept];
export const getEthnicities = getLookupData<EthnicityResponse>(ethnicityPath);

const hairColorPath: keyof paths = "/hair-color";
export type HairColorResponse =
  paths[typeof hairColorPath]["get"]["responses"]["200"]["content"][typeof accept];
export const getHairColors = getLookupData<HairColorResponse>(hairColorPath);

const eyeColorPath: keyof paths = "/eye-color";
export type EyeColorResponse =
  paths[typeof eyeColorPath]["get"]["responses"]["200"]["content"][typeof accept];
export const getEyeColors = getLookupData<EyeColorResponse>(eyeColorPath);

const genderPath: keyof paths = "/gender";
export type GenderResponse =
  paths[typeof genderPath]["get"]["responses"]["200"]["content"][typeof accept];
export const getGenders = getLookupData<GenderResponse>(genderPath);

const unionStatusPath: keyof paths = "/union-status";
export type UnionStatusResponse =
  paths[typeof unionStatusPath]["get"]["responses"]["200"]["content"][typeof accept];
export const getUnionStatuses =
  getLookupData<UnionStatusResponse>(unionStatusPath);

const provincePath: keyof paths = "/province";
export type ProvinceResponse =
  paths[typeof provincePath]["get"]["responses"]["200"]["content"][typeof accept];
export const getProvinces = getLookupData<ProvinceResponse>(provincePath);

const languagePath: keyof paths = "/language";
export type LanguageResponse =
  paths[typeof languagePath]["get"]["responses"]["200"]["content"][typeof accept];
export const getLanguages = getLookupData<LanguageResponse>(languagePath);
