import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import React from "react";
import { Controller, useForm } from "react-hook-form";

export type EditUserData = {
  id: string;
  email: string;
  isActive: boolean;
};

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
  },
  formLayout: {
    flexGrow: 1,
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function BasicDataEditForm(props: {
  user: EditUserData;
  onSubmit: (user: EditUserData) => void | Promise<void>;
  onCancel: () => void | Promise<void>;
  className?: string;
}): JSX.Element {
  const { classes } = useStyles();
  const { user, onSubmit, onCancel, className } = props;

  type FormData = Omit<EditUserData, "id">;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: "all",
    defaultValues: {
      email: user.email,
      isActive: user.isActive,
    },
  });

  return (
    <Paper className={className}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit((formData) => {
          void onSubmit({
            email: formData.email,
            id: user.id,
            isActive: formData.isActive,
          });
        })}
      >
        <Grid container spacing={3} className={classes.formLayout}>
          <Grid item xs={12}>
            <Controller
              rules={{
                required: "This field is required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  aria-label="Email"
                  error={errors.email !== undefined}
                  helperText={errors.email?.message}
                  variant="standard"
                />
              )}
              name="email"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  value="isActive"
                  control={
                    <Switch {...field} checked={field.value} color="primary" />
                  }
                  label="Active"
                  labelPlacement="end"
                />
              )}
              name="isActive"
              control={control}
            />
          </Grid>
        </Grid>
        <div>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.actionButton}
          >
            Save
          </Button>
          <Button
            color="secondary"
            onClick={onCancel}
            className={classes.actionButton}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Paper>
  );
}
