import { components } from "../../../api/schema/v1";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth0 } from "../../../auth0";
import { removeClaim } from "../../../claims/api";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const useStyles = makeStyles()(() => ({
  table: {
    minWidth: 650,
  },
}));

type Claim = components["schemas"]["Claim"];

export default function ClaimListing(props: {
  claims: Claim[];
  isEditable: boolean;
  onDelete: (request: { id: number }) => void;
}): JSX.Element {
  const { claims, isEditable, onDelete } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { classes } = useStyles();
  const handleDelete = async (claim: { id: number }) => {
    const token = await getAccessTokenSilently();
    await removeClaim(claim, {
      token,
    });
    onDelete(claim);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="claims table">
        <TableHead>
          <TableRow>
            <TableCell>Permission group</TableCell>
            <TableCell>Permission name</TableCell>
            <TableCell>Created at</TableCell>
            {isEditable ? <TableCell>Actions</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {claims.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle1" gutterBottom>
                  <Box fontFamily="Monospace" fontWeight="fontWeightBold">
                    {row.type.group.name}
                  </Box>
                </Typography>
                <Typography variant="caption">
                  <Box fontStyle="italic">{row.type.group.description}</Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>
                  <Box fontFamily="Monospace">{row.type.name}</Box>
                </Typography>
                <Typography variant="caption">
                  <Box fontStyle="italic">{row.type.description}</Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">
                  {new Date(row.createdAt).toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </Typography>
              </TableCell>
              {isEditable ? (
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={async () => {
                      await handleDelete(row);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
