import React, { useEffect, useState } from "react";
import { useAuth0, useProfile } from "../../auth0";
import { userClaims } from "../../claims";
import AccessDeniedPanel from "../../components/AccessDeniedPanel";
import {
  exportRestrictedListingToCsv,
  listRestrictedTalentProfiles,
  listTalentProfiles,
  TalentItem,
} from "./api";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { makeStyles } from "tss-react/mui";
import LoadingIndicator from "../../components/LoadingIndicator";
import Grid from "@mui/material/Grid";
import TalentListItem from "./components/TalentListItem";
import Container from "@mui/material/Container";
import FilterControlPanel, {
  FilterData,
} from "./components/FilterControlPanel";
import ListingPagination, {
  PageInfo,
} from "../../components/ListingPagination";
import {
  getFirstValidationError,
  ValidationProblemDetails,
} from "../../api/validation";
import Typography from "@mui/material/Typography";
import { isTimeoutError } from "../../api";
import TemporarySnackbar from "../../components/TemporarySnackbar";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  controls: {
    marginBottom: theme.spacing(4),
  },
  pagination: {
    marginTop: theme.spacing(4),
  },
}));

export default function TalentProfileListing(props: {
  restricted: boolean;
}): JSX.Element {
  const { classes } = useStyles();
  const { restricted } = props;
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [busy, setBusy] = useState(false);
  const { profile } = useProfile();

  const [filter, setFilter] = useState<FilterData | null>(null);
  const [listing, setListing] = useState<TalentItem[]>([]);
  const [page, setPage] = useState<PageInfo | null>(null);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const hasAccess =
    isAuthenticated &&
    profile?.claims?.some((c) => userClaims.details.equals(c.type)) === true;

  const getSearchResults = async () => {
    setBusy(true);
    try {
      const token = await getAccessTokenSilently();
      if (restricted) {
        const response = await listRestrictedTalentProfiles(
          {
            ...filter?.data,
            limit: page?.size,
            page: page?.page,
          },
          {
            token,
          },
        );
        setListing(
          response.data.map((i) => ({
            type: "restricted",
            data: i,
          })),
        );
        setTotal(response.totalCount);
      } else {
        const response = await listTalentProfiles(
          { ...filter?.data, limit: page?.size, page: page?.page },
          { token },
        );
        setListing(
          response.data.map((i) => ({
            type: "regular",
            data: i,
          })),
        );
        setTotal(response.totalCount);
      }

      setError(null);
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setError(validationError.message);
          return;
        }
      }
      if (isTimeoutError(err)) {
        setError(
          "Search request failed due to network connectivity issues, please try again",
        );
      } else {
        setError(
          "Error occurred when listing user profiles. Please try again...",
        );
      }
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to fetch the user profile listing",
          data: {
            request: error.request as unknown,
            response: error.response,
          },
        }),
      );
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    if (!hasAccess || page === null || filter === null) {
      return;
    }

    void getSearchResults();
  }, [filter, hasAccess, page]);

  if (!hasAccess) {
    return <AccessDeniedPanel />;
  }

  const downloadFile = (contents: Blob) => {
    try {
      const url = URL.createObjectURL(contents);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("visibility", "hidden");
      link.download = `talent-profile-search-${Date.now()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError(
        "Failed to create a CSV file to download. Please contact support.",
      );
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to create a CSV file for export",
        }),
      );
    }
  };

  const exportToCsv = async () => {
    if (!restricted) {
      return;
    }
    if (filter === null) {
      return;
    }

    setBusy(true);

    try {
      const token = await getAccessTokenSilently();
      const data = await exportRestrictedListingToCsv(
        {
          ...filter?.data,
          limit: 20_000_000,
          page: 1,
        },
        { token },
      );
      const blob = new Blob([data], { type: "text/csv" });
      downloadFile(blob);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Container className={classes.root}>
      <FilterControlPanel
        type={restricted ? "include-restricted" : "regular"}
        onFilterChange={setFilter}
        onCsvExport={exportToCsv}
      />
      <Grid container spacing={1}>
        {listing.length > 0 ? (
          listing.map((item) => (
            <Grid key={item.data.user.id} item lg={3}>
              <TalentListItem item={item} />
            </Grid>
          ))
        ) : (
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="subtitle1">
              <em>No results found</em>
            </Typography>
          </Grid>
        )}
      </Grid>
      <ListingPagination
        className={classes.pagination}
        total={total}
        onPageChange={setPage}
        /**
         * We use a different value than the one we use in other places, which is exported from the 'api' module.
         * Here we have a layout of 6 rows of 4 items, which means that a default page size that looks good right now is 24.
         * With 25 items, we always have one 'trailing' item.
         *
         * Of course if we change the layout, especially by adding columns, we'll have to adjust this value as well so it looks good by default.
         */
        defaultPageSize={4 * 6}
      />
      <div>
        {busy ? <LoadingIndicator backdrop /> : null}
        <TemporarySnackbar message={error} clearMessage={setError} />
      </div>
    </Container>
  );
}
