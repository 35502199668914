import Paper from "@mui/material/Paper";
import { createNote, NoteCreationRequest } from "./api";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "tss-react/mui";
import { useAuth0, useProfile } from "../../../auth0";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Sentry from "@sentry/react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {
  ValidationProblemDetails,
  getFirstValidationError,
} from "../../../api/validation";
import { AxiosError } from "axios";
import { isTimeoutError } from "../../../api";
import TemporarySnackbar from "../../../components/TemporarySnackbar";
import { ComponentState } from "../../../components/ComponentState";
import { useState } from "react";

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
  },
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formLayout: {
    flexGrow: 1,
  },
  submitButton: {
    marginTop: theme.spacing(1),
  },
}));

export function NoteCreationPanel(props: {
  talentProfileId: string | number;
  onCreated: (id: number) => void;
}): JSX.Element {
  const { talentProfileId, onCreated } = props;
  const { classes } = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useProfile();
  const [componentState, setComponentState] = useState<ComponentState | null>(
    null,
  );

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<NoteCreationRequest>({
    criteriaMode: "all",
    defaultValues: {
      content: "",
    },
  });

  async function saveNote(request: NoteCreationRequest) {
    setComponentState({ state: "busy" });
    try {
      const token = await getAccessTokenSilently();
      await createNote(
        {
          ...request,
          talentProfileId,
        },
        { token },
      );
      setValue("content", "");
      setComponentState(null);
      onCreated(Date.now());
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setComponentState({
            state: "error",
            message: validationError.message,
          });
          return;
        }
      }

      if (isTimeoutError(err)) {
        setComponentState({
          state: "error",
          message:
            "Note creation request failed due to network connectivity issues. Please try again.",
        });
      } else {
        setComponentState({
          state: "error",
          message: "An error occurred when creating a note, please try again",
        });
      }

      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to create a talent profile note",
          data: {
            talentProfileId: talentProfileId,
            user: profile?.id,
          },
        }),
      );
    }
  }

  return (
    <Paper className={classes.root} data-testid="note-editor">
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit((formData) => {
          return saveNote(formData);
        })}
      >
        <Grid container>
          <Grid item xs>
            {componentState?.state === "busy" ? <LoadingIndicator /> : null}
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field is required",
                  maxLength: 1000,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Note"
                    error={errors.content !== undefined}
                    helperText={errors.content?.message}
                    multiline
                    disabled={componentState?.state === "busy"}
                    inputProps={{ maxLength: 1000 }}
                    variant="standard"
                  />
                )}
                name="content"
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={componentState?.state === "busy"}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {componentState?.state === "error" && (
        <TemporarySnackbar
          message={componentState?.message}
          clearMessage={setComponentState}
        />
      )}
    </Paper>
  );
}
