import { makeStyles } from "tss-react/mui";
import { BlogPost } from "../api";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PostContent, { getImageUrl } from "./PostContent";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  pinned: {
    alignSelf: "center",
    color: theme.palette.secondary.main,
  },
  title: {
    flexGrow: 1,
  },
  thumbnailImage: {
    maxWidth: 120,
    height: 80,
    marginRight: theme.spacing(1),
  },
  summary: {
    marginTop: theme.spacing(1),
  },
  dark: {
    background: theme.palette.grey[200],
  },
}));

export default function PostItem(props: {
  post: BlogPost;
  background?: "dark" | "light";
}): JSX.Element {
  const { post, background = "light" } = props;
  const { classes, cx } = useStyles();
  const imageUrl = getImageUrl(post);

  return (
    <Container
      className={cx(
        classes.root,
        background === "dark" ? classes.dark : undefined,
      )}
      maxWidth="md"
    >
      <Box display="flex" borderRadius="4px">
        <img
          className={classes.thumbnailImage}
          src={imageUrl}
          alt={post.title}
        />
        <PostContent post={post} variant="summary" />
      </Box>
    </Container>
  );
}
