import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "tss-react/mui";
import React, { useEffect, useMemo, useState } from "react";
import {
  ProfileStatus,
  RestrictedTalentProfileListingRequest,
  TalentProfileListingRequest,
} from "../api";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {
  getEthnicities,
  getGenders,
  getUnionStatuses,
  LookupData,
} from "../../lookup-api";
import { useAuth0 } from "../../../auth0";
import * as Sentry from "@sentry/react";
import FormHelperText from "@mui/material/FormHelperText";
import { useLocation, useNavigate } from "react-router-dom";
import { tryParseInt } from "../../../parsing";
import { ImportExport } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { isTimeoutError } from "../../../api";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  controls: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  searchControl: {
    display: "flex",
    margin: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dropDown: {
    minWidth: 150,
  },
}));

function LoadedSelect(props: {
  className?: string;
  id: string;
  label: string;
  onChange: (value: unknown) => void;
  data: LookupData[] | null | undefined;
  error?: string | null;
  loading: boolean;
  value: number | string;
}) {
  const { className, id, label, onChange, data, error, loading, value } = props;
  return (
    <FormControl className={className}>
      <InputLabel id={`${id}-label`} variant="standard">
        {label}
      </InputLabel>
      <Select
        value={value.toString()}
        id={`${id}-select`}
        labelId={`${id}-label`}
        disabled={loading}
        onChange={(event) => onChange(event.target.value)}
        variant="standard"
      >
        <MenuItem value="">
          <em>Clear</em>
        </MenuItem>
        {(data ?? []).map((v) => (
          <MenuItem key={v.id} value={v.id}>
            {v.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  );
}

function SliderControl(props: {
  className?: string;
  type: string;
  label: string;
  value: [number, number];
  defaultEnabled?: boolean;
  min?: number;
  max?: number;
  onChange: (range: [number, number] | null) => void;
}) {
  const {
    type,
    min,
    max,
    value: val,
    onChange,
    label,
    defaultEnabled = false,
    className,
  } = props;
  const [enabled, setEnabled] = useState(defaultEnabled);
  const [value, setValue] = useState<[number, number]>(val);

  return (
    <FormControl fullWidth className={className} aria-label={`${type} slider`}>
      <Slider
        id={`${type}-slider`}
        value={value}
        min={min}
        max={max}
        disabled={!enabled}
        onChange={(_, newValue) => {
          if (!Array.isArray(newValue)) {
            return;
          }
          setValue(newValue as [number, number]);
        }}
        valueLabelDisplay="on"
        onChangeCommitted={() => {
          onChange(value);
        }}
        aria-labelledby={`${type}-slider`}
        size="small"
      />
      <Typography id={`${type}-slider`} gutterBottom>
        {label}{" "}
        <Checkbox
          checked={enabled}
          aria-label={`${type} slider checkbox`}
          onChange={(event) => {
            const checked = event.target.checked;
            if (checked) {
              onChange(value);
            } else {
              onChange(null);
            }
            setEnabled(checked);
          }}
        />
      </Typography>
    </FormControl>
  );
}

export type FilterData =
  | { type: "regular"; data: TalentProfileListingRequest }
  | { type: "restricted"; data: RestrictedTalentProfileListingRequest };

type Lookups = {
  genders: LookupData[];
  ethnicities: LookupData[];
  unionStatuses: LookupData[];
};

export default function FilterControlPanel(props: {
  type: "regular" | "include-restricted";
  onFilterChange: (data: FilterData) => void | Promise<void>;
  onCsvExport: () => void;
}): JSX.Element {
  const { classes, cx } = useStyles();
  const { type, onFilterChange, onCsvExport } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const queryValues = useMemo(() => {
    const queryString = new URLSearchParams(location.search);
    return {
      "age.max": tryParseInt(queryString.get("age.max")) ?? undefined,
      "age.min": tryParseInt(queryString.get("age.min")) ?? undefined,
      "height.max": tryParseInt(queryString.get("height.max")) ?? undefined,
      "height.min": tryParseInt(queryString.get("height.min")) ?? undefined,
      "weight.min": tryParseInt(queryString.get("weight.min")) ?? undefined,
      "weight.max": tryParseInt(queryString.get("weight.max")) ?? undefined,
      query: queryString.get("query") ?? undefined,
      unionStatus: tryParseInt(queryString.get("unionStatus")) ?? undefined,
      ethnicity: tryParseInt(queryString.get("ethnicity")) ?? undefined,
      gender: tryParseInt(queryString.get("gender")) ?? undefined,
    };
  }, [location.search]);
  const MAX_QUERY_LENGTH = 100;
  const [executing, setExecuting] = useState(false);
  // The query is handled separately, because we don't want to automatically kick off the search on every character change
  const [query, setQuery] = useState(queryValues.query ?? "");

  const isQueryOverLimit = query.length > MAX_QUERY_LENGTH;
  const [lookup, setLookup] = useState<Lookups | null>(null);
  const [lookupLoading, setLookupLoading] = useState(false);
  const [lookupFetchError, setLookupFetchError] = useState<string | null>(null);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [regularControls, setRegularControls] =
    useState<TalentProfileListingRequest>(queryValues);
  const [restrictedControls, setRestrictedControls] =
    useState<RestrictedTalentProfileListingRequest | null>(
      type === "include-restricted"
        ? {
            profileStatus: "complete",
          }
        : null,
    );

  const executeSearch = async () => {
    setExecuting(true);
    try {
      await onFilterChange({
        type: type === "include-restricted" ? "restricted" : "regular",
        data: {
          ...regularControls,
          ...restrictedControls,
          query:
            regularControls.query !== undefined && regularControls.query !== ""
              ? regularControls.query
              : undefined,
        },
      });
    } finally {
      setExecuting(false);
    }
  };

  useEffect(() => {
    const queryString = new URLSearchParams(location.search);
    for (const [property, value] of Object.entries(regularControls ?? {})) {
      if (value === undefined) {
        queryString.delete(property);
      } else {
        queryString.set(property, value.toString());
      }
    }

    navigate({ search: queryString.toString() });
  }, [regularControls]);

  useEffect(() => {
    void executeSearch();
  }, [regularControls, restrictedControls]);

  useEffect(() => {
    async function getLookups() {
      setLookupLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const requestOptions = { token };
        const [genders, ethnicities, unionStatuses] = await Promise.all([
          getGenders(requestOptions),
          getEthnicities(requestOptions),
          getUnionStatuses(requestOptions),
        ]);

        setLookup({
          ethnicities,
          genders,
          unionStatuses: unionStatuses.concat({ id: -1, name: "None" }),
        });
      } catch (error) {
        if (isTimeoutError(error)) {
          setLookupFetchError(
            "One of the drop down data requests failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          setLookupFetchError("Could not load drop down data");
        }
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message:
              "Lookup data could not be loaded in talent profile listing filter control panel",
          }),
        );
      } finally {
        setLookupLoading(false);
      }
    }
    if (!isAuthenticated) {
      return;
    }

    if (lookup !== null) {
      return;
    }

    void getLookups();
  }, []);

  const profileStatuses: readonly [
    ProfileStatus,
    ProfileStatus,
    ProfileStatus,
  ] = ["missing", "incomplete", "complete"] as const;
  return (
    <Paper className={classes.root}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setRegularControls((prev) => ({
            ...prev,
            query,
          }));
        }}
      >
        <Grid container className={classes.controls} spacing={3}>
          <Grid item md></Grid>
          <Grid item md>
            <FormControl fullWidth className={classes.searchControl}>
              <InputLabel htmlFor="search-box" variant="standard">
                Search
              </InputLabel>
              <Input
                id="search-box"
                type="text"
                value={query}
                disabled={executing}
                error={isQueryOverLimit}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="execute search"
                      onClick={() => {
                        setRegularControls((prev) => ({
                          ...prev,
                          query,
                        }));
                      }}
                      size="large"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {isQueryOverLimit ? (
                <FormHelperText error>
                  Search text will be truncated to the first 100 characters
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item md></Grid>
          <Grid item container spacing={2}>
            <Grid item md>
              <SliderControl
                type="age"
                min={0}
                max={120}
                value={[
                  regularControls["age.min"] ?? 18,
                  regularControls["age.max"] ?? 80,
                ]}
                onChange={(value) =>
                  setRegularControls((prev) => ({
                    ...prev,
                    "age.min": value ? value[0] : undefined,
                    "age.max": value ? value[1] : undefined,
                  }))
                }
                className={classes.searchControl}
                defaultEnabled={
                  regularControls["age.min"] !== undefined ||
                  regularControls["age.max"] !== undefined
                }
                label="Age"
              />
            </Grid>
            <Grid item md>
              <SliderControl
                type="height"
                min={0}
                max={300}
                value={[
                  regularControls["height.min"] ?? 150,
                  regularControls["height.max"] ?? 210,
                ]}
                onChange={(value) =>
                  setRegularControls((prev) => ({
                    ...prev,
                    "height.min": value ? value[0] : undefined,
                    "height.max": value ? value[1] : undefined,
                  }))
                }
                defaultEnabled={
                  regularControls["height.min"] !== undefined ||
                  regularControls["height.max"] !== undefined
                }
                className={classes.searchControl}
                label="Height (cm)"
              />
            </Grid>
            <Grid item md>
              <SliderControl
                type="weight"
                min={0}
                max={500}
                value={[
                  regularControls["weight.min"] ?? 100,
                  regularControls["weight.max"] ?? 240,
                ]}
                onChange={(value) =>
                  setRegularControls((prev) => ({
                    ...prev,
                    "weight.min": value ? value[0] : undefined,
                    "weight.max": value ? value[1] : undefined,
                  }))
                }
                defaultEnabled={
                  regularControls["weight.min"] !== undefined ||
                  regularControls["weight.max"] !== undefined
                }
                className={classes.searchControl}
                label="Weight (lbs)"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs>
              <LoadedSelect
                className={cx(classes.searchControl, classes.dropDown)}
                id="gender"
                label="Gender"
                loading={lookupLoading}
                error={lookupFetchError}
                onChange={(value) =>
                  setRegularControls((prev) => ({
                    ...prev,
                    gender: value ? (value as number) : undefined,
                  }))
                }
                data={lookup?.genders}
                value={regularControls?.gender ?? ""}
              />
            </Grid>
            <Grid item xs>
              <LoadedSelect
                className={cx(classes.searchControl, classes.dropDown)}
                id="ethnicity"
                label="Ethnicity"
                loading={lookupLoading}
                error={lookupFetchError}
                onChange={(value) =>
                  setRegularControls((prev) => ({
                    ...prev,
                    ethnicity: value ? (value as number) : undefined,
                  }))
                }
                data={lookup?.ethnicities}
                value={regularControls?.ethnicity ?? ""}
              />
            </Grid>
            <Grid item xs>
              <LoadedSelect
                className={cx(classes.searchControl, classes.dropDown)}
                id="union-status"
                label="Union status"
                loading={lookupLoading}
                error={lookupFetchError}
                onChange={(value) =>
                  setRegularControls((prev) => ({
                    ...prev,
                    unionStatus: value ? (value as number) : undefined,
                  }))
                }
                data={lookup?.unionStatuses}
                value={regularControls?.unionStatus ?? ""}
              />
            </Grid>
          </Grid>
          {restrictedControls !== null ? (
            <Grid item container data-testid="restricted-row">
              <Grid item xs>
                <FormControl
                  className={cx(classes.searchControl, classes.dropDown)}
                >
                  <InputLabel id="profile-status-label" variant="standard">
                    Profile status
                  </InputLabel>
                  <Select
                    value={restrictedControls.profileStatus ?? ""}
                    id="profile-status-select"
                    labelId="profile-status-label"
                    onChange={(event) =>
                      setRestrictedControls((prev) => ({
                        ...prev,
                        profileStatus: (event.target.value
                          ? event.target.value
                          : undefined) as ProfileStatus | undefined,
                      }))
                    }
                    variant="standard"
                  >
                    <MenuItem value="">
                      <em>Clear</em>
                    </MenuItem>
                    <MenuItem value={profileStatuses[0]}>Missing</MenuItem>
                    <MenuItem value={profileStatuses[1]}>Incomplete</MenuItem>
                    <MenuItem value={profileStatuses[2]}>Complete</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md>
                <Grid item xs xl={6}>
                  <TextField
                    label="Paid after"
                    className={classes.searchControl}
                    type="date"
                    value={restrictedControls["paid.after"] ?? ""}
                    onChange={(event) =>
                      setRestrictedControls((prev) => ({
                        ...prev,
                        "paid.after": event.target.value
                          ? event.target.value
                          : undefined,
                      }))
                    }
                    InputLabelProps={{ shrink: true, required: false }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs xl={6}>
                  <TextField
                    label="Paid before"
                    className={classes.searchControl}
                    type="date"
                    value={restrictedControls["paid.before"] ?? ""}
                    onChange={(event) =>
                      setRestrictedControls((prev) => ({
                        ...prev,
                        "paid.before": event.target.value
                          ? event.target.value
                          : undefined,
                      }))
                    }
                    InputLabelProps={{ shrink: true, required: false }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid item container md>
                <Grid item xs xl={6}>
                  <TextField
                    label="Joined after"
                    className={classes.searchControl}
                    type="date"
                    value={restrictedControls["created.after"] ?? ""}
                    onChange={(event) =>
                      setRestrictedControls((prev) => ({
                        ...prev,
                        "created.after": event.target.value
                          ? event.target.value
                          : undefined,
                      }))
                    }
                    InputLabelProps={{ shrink: true, required: false }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs xl={6}>
                  <TextField
                    label="Joined before"
                    className={classes.searchControl}
                    type="date"
                    value={restrictedControls["created.before"] ?? ""}
                    onChange={(event) =>
                      setRestrictedControls((prev) => ({
                        ...prev,
                        "created.before": event.target.value
                          ? event.target.value
                          : undefined,
                      }))
                    }
                    InputLabelProps={{ shrink: true, required: false }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyItems="space-between"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Button type="submit" color="secondary">
                  Search
                </Button>
              </Box>
              {type === "include-restricted" ? (
                <Button
                  startIcon={<ImportExport />}
                  onClick={onCsvExport}
                  color="secondary"
                >
                  CSV Export
                </Button>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
