import { RequestOptions, settings } from "../../api";
import axios from "axios";
import { paths } from "../../api/schema/v1";

const ownProfilePath: keyof paths = "/user/profile/self";
type OwnProfileResponse =
  paths[typeof ownProfilePath]["get"]["responses"]["200"];
const contentType = "application/json;v=1";

export type OwnUserProfile = OwnProfileResponse["content"][typeof contentType];

export async function getOwnProfile(
  options: RequestOptions,
): Promise<OwnUserProfile> {
  const { token } = options;
  const response = await axios.get<OwnUserProfile>(
    `${settings.url}${ownProfilePath}`,
    {
      headers: {
        Accept: contentType,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}

const profilePath: keyof paths = "/user/profile/{id}";
type ProfileResponse = paths[typeof profilePath]["get"]["responses"]["200"];
type ProfileRequest = paths[typeof profilePath]["get"]["parameters"]["path"];

export type UserProfile = ProfileResponse["content"][typeof contentType];

export async function getProfile(
  request: ProfileRequest,
  options: RequestOptions,
): Promise<UserProfile> {
  const { token } = options;
  const response = await axios.get<UserProfile>(
    `${settings.url}${profilePath.replace("{id}", request.id)}`,
    {
      headers: {
        Accept: contentType,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}
