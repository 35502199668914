import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { RequestOptions } from "./api";
import {
  getEthnicities,
  getEyeColors,
  getGenders,
  getHairColors,
  getLanguages,
  getProvinces,
  getUnionStatuses,
  LookupData,
} from "./talent/lookup-api";
import { useAuth0 } from "./auth0";
import * as Sentry from "@sentry/react";

export type LookupsData = {
  genders: LookupData[];
  ethnicities: LookupData[];
  hairColors: LookupData[];
  eyeColors: LookupData[];
  provinces: LookupData[];
  unionStatuses: LookupData[];
  languages: LookupData[];
};

type LookupContextType = {
  isLoading: boolean;
  lookups: LookupsData | Error | undefined;
};

export const LookupContext = createContext<LookupContextType>({
  isLoading: false,
  lookups: undefined,
});

export const useLookups: () => LookupContextType = () =>
  useContext(LookupContext);

export function LookupProvider({
  children,
}: {
  children?: ReactNode;
}): JSX.Element {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [busy, setBusy] = useState<boolean>(true);
  const [lookups, setLookups] = useState<LookupsData | Error | undefined>(
    undefined,
  );

  async function getAllLookups(): Promise<LookupsData> {
    const options: RequestOptions = {
      token: await getAccessTokenSilently(),
    };
    const [
      genders,
      ethnicities,
      hairColors,
      eyeColors,
      unionStatuses,
      provinces,
      languages,
    ] = await Promise.all([
      getGenders(options),
      getEthnicities(options),
      getHairColors(options),
      getEyeColors(options),
      getUnionStatuses(options),
      getProvinces(options),
      getLanguages(options),
    ]);

    return {
      genders,
      ethnicities,
      hairColors,
      eyeColors,
      unionStatuses,
      provinces,
      languages,
    };
  }

  useEffect(() => {
    async function fetchLookups() {
      setBusy(true);
      try {
        const lookups = await getAllLookups();
        setLookups(lookups);
      } catch (error) {
        setLookups(
          new Error(
            "An error occurred when fetching lookup data, please refresh and try again",
          ),
        );
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: `Failed to fetch at least one of the lookups`,
          }),
        );
      } finally {
        setBusy(false);
      }
    }
    if (lookups !== undefined || !isAuthenticated) {
      return;
    }

    void fetchLookups();
  }, [lookups, isAuthenticated]);

  return (
    <LookupContext.Provider value={{ lookups, isLoading: busy }}>
      {children}
    </LookupContext.Provider>
  );
}
