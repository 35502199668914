import { Avatar, Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "tss-react/mui";
import { NoteItem } from "../api";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import NoteDeleteButton from "./NoteDeleteDialog";

const useStyles = makeStyles()((theme) => ({
  interpunct: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  header: {
    wordBreak: "break-word",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(0.5),
    whiteSpace: "pre-line",
  },
}));

export default function NoteListItem(props: {
  item: NoteItem;
  reference?: Date;
  onDelete: (note: { id: number }) => void;
}): JSX.Element {
  const { item, onDelete } = props;
  const { classes } = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <Avatar
              alt={item.createdBy?.email ?? "N/A"}
              src={item.createdBy?.avatarUrl ?? undefined}
            />
          </Grid>
          <Grid item container>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.header}
            >
              {item.createdBy !== undefined ? (
                <MuiLink
                  component={Link}
                  to={`${"/user"}/${encodeURIComponent(item.createdBy?.id)}`}
                >
                  {item.createdBy?.email}
                </MuiLink>
              ) : (
                <em>user deleted</em>
              )}
              <Typography
                className={classes.interpunct}
                variant="body2"
                color="textSecondary"
                component="span"
              >
                ·
              </Typography>
              {new Date(item.createdAt).toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row-reverse">
              {onDelete ? (
                <NoteDeleteButton onDelete={onDelete} noteId={item.id} />
              ) : null}
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item />
          <Grid>
            <Typography variant="body1" className={classes.content}>
              {item.content}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
