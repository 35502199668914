import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import { deleteUser, updateUser } from "../api";
import BasicDataEditForm, { EditUserData } from "./BasicDataEditForm";
import BasicUserDataDisplay, { UserData } from "./BasicUserDataDisplay";
import * as Sentry from "@sentry/react";
import {
  getFirstValidationError,
  ValidationProblemDetails,
} from "../../api/validation";
import { AxiosError } from "axios";
import { useAuth0 } from "../../auth0";
import { useNavigate } from "react-router-dom";
import TemporarySnackbar from "../../components/TemporarySnackbar";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  error: {
    merginTop: theme.spacing(1),
  },
}));

export type UserChangeData = {
  email: string;
  isActive: boolean;
};

export default function BasicUserData(props: {
  className?: string;
  user: UserData;
  editable: boolean;
  onChange?: (data: UserChangeData) => void | Promise<void>;
}): JSX.Element {
  const { classes, cx } = useStyles();
  const { user: userData, editable, className, onChange } = props;
  const { getAccessTokenSilently, logout, user: adminData } = useAuth0();
  const [user, setUser] = useState(userData);
  const [error, setError] = useState<string | null>(null);
  const [busy, setBusy] = useState(false);
  const [mode, setMode] = useState<"editor" | "display">("display");
  const navigate = useNavigate();

  const deleteUserData = async () => {
    setBusy(true);

    try {
      const token = await getAccessTokenSilently();
      const request = {
        id: user.id,
      };
      await deleteUser(request, { token });
      if (user.id !== adminData?.sub) {
        navigate("/user");
        return;
      }
      logout();
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setError(validationError.message);
          return;
        }
      }
      setError(
        "An error occurred when deleting the user data. Please try again.",
      );
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to delete user data",
          data: {
            adminUser: adminData,
            targetUser: userData,
            request: error.request as unknown,
            response: error.response,
          },
        }),
      );
    } finally {
      setBusy(false);
    }
  };

  const updateUserData = async (data: EditUserData) => {
    setBusy(true);

    try {
      const token = await getAccessTokenSilently();
      const request = {
        email: data.email,
        isActive: data.isActive,
      };
      await updateUser(user.id, request, { token });
      setUser((p) => ({
        avatarUrl: p.avatarUrl,
        ...data,
      }));
      setMode("display");
      await onChange?.(request);
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setError(validationError.message);
          return;
        }
      }
      setError(
        "An error occurred when editing the user data. Please try again...",
      );
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to edit user data",
          data: {
            user,
            request: error.request as unknown,
            response: error.response,
          },
        }),
      );
    } finally {
      setBusy(false);
    }
  };

  if (mode === "editor") {
    return (
      <>
        <BasicDataEditForm
          user={user}
          className={cx(classes.root, className)}
          onCancel={() => setMode("display")}
          onSubmit={updateUserData}
        />
        {busy ? <LoadingIndicator /> : null}
        <TemporarySnackbar message={error} clearMessage={setError} />
      </>
    );
  }

  return (
    <>
      <BasicUserDataDisplay
        user={user}
        className={cx(classes.root, className)}
        onEdit={editable ? () => setMode("editor") : undefined}
        onDelete={deleteUserData}
      />
      {busy ? <LoadingIndicator /> : null}
      <TemporarySnackbar message={error} clearMessage={setError} />
    </>
  );
}
