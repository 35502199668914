import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles()((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
}));

export default function NoteDeleteButton(props: {
  noteId: number;
  onDelete: (note: { id: number }) => void;
}): JSX.Element {
  const { classes } = useStyles();
  const { onDelete, noteId } = props;
  const [open, setOpen] = useState(false);
  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={"Delete the note permanently"}>
        <IconButton
          aria-label="delete the note"
          onClick={() => setOpen(true)}
          className={classes.deleteIcon}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="note-delete-dialog-title"
      >
        <DialogTitle id="note-delete-dialog-title">
          Confirm note deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to delete this note? This action is permanent
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          {
            <Button
              onClick={() => onDelete({ id: noteId })}
              variant="text"
              className={classes.deleteButton}
            >
              Yes, delete the note
            </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}
