import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import { ChangeEventHandler, useState } from "react";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import {
  FileType,
  generateOwnSignedUrl,
  generateSignedUrl,
  updateFileMetadata,
  updateOwnFileMetadata,
  uploadFile,
} from "../api";
import UserFileAsset from "./UserFileAsset";
import * as Sentry from "@sentry/react";

function determineFileSizeLimit(type: FileType) {
  switch (type) {
    case "video":
      return 50;
    default:
      return 10;
  }
}

export type AssetFileType = { id: FileType; name: string };

export default function GalleryItem(props: {
  type: AssetFileType;
  talentProfile: {
    id: string | number;
  };
  edit: "own-files" | "other-user" | "disabled";
  url?: string;
  onFileUploaded?: (type: FileType) => void;
  onFileUploadStarted?: (type: FileType) => void;
  onFileUploadEnded?: (type: FileType) => void;
}): JSX.Element {
  const {
    type,
    url: defaultUrl,
    edit,
    onFileUploaded,
    onFileUploadEnded,
    onFileUploadStarted,
    talentProfile,
  } = props;

  const accept = type.id === "video" ? "video/*" : "image/*";

  const [url, setUrl] = useState<string | null>(defaultUrl ?? null);
  const { getAccessTokenSilently } = useAuth0();
  const [busy, setBusy] = useState<boolean>(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const sizeLimit = determineFileSizeLimit(type.id);

  const createSignedUrl = async (contentType: string | null, token: string) => {
    try {
      const url =
        edit === "own-files"
          ? await generateOwnSignedUrl(
              { type: type.id, contentType },
              { token },
            )
          : await generateSignedUrl(
              {
                id: talentProfile.id,
                type: type.id,
                contentType,
              },
              { token },
            );
      return { type: "data" as const, value: url };
    } catch (error) {
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to create a signed URL for file upload",
          data: {
            talentProfile,
            type,
            edit,
          },
        }),
      );
      return {
        type: "error" as const,
        message: "Failed to start the image upload",
      };
    }
  };

  const updateFile = async (version: number, token: string) => {
    try {
      const data =
        edit === "own-files"
          ? await updateOwnFileMetadata(
              {
                fileType: type.id,
                version,
              },
              { token },
            )
          : await updateFileMetadata(
              {
                fileType: type.id,
                version,
                id: talentProfile.id,
              },
              { token },
            );
      return { type: "data" as const, value: data };
    } catch (error) {
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to update an image metadata",
          data: {
            talentProfile,
            type,
            version,
            edit,
          },
        }),
      );
      return {
        type: "error" as const,
        message: "Failed to update the image metadata, please try again...",
      };
    }
  };

  const upload = async (file: File, url: string, contentType?: string) => {
    try {
      await uploadFile(
        {
          file,
          url,
          contentType,
        },
        (event) => {
          setProgress(Math.round(100 * event.loaded) / event.total);
        },
      );
    } catch (error) {
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to upload an image to S3",
          data: {
            talentProfile,
            type,
            url,
            file: file.name,
            edit,
          },
        }),
      );
      return {
        type: "error" as const,
        message: "Failed to upload the image, please try again...",
      };
    }
  };

  const onFileSelected: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setBusy(true);
    onFileUploadStarted?.(type.id);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const file = e.target?.files?.[0];
      if (!file) {
        return;
      }

      if (file.size > sizeLimit * 1000000) {
        setError(`Selected file exceeds the size limit of ${sizeLimit} MB`);
        return;
      }

      const signedUrlResult = await createSignedUrl(file.type, token);
      if (signedUrlResult.type === "error") {
        setError(signedUrlResult.message);
        return;
      }

      const uploadResult = await upload(
        file,
        signedUrlResult.value.url,
        file.type,
      );
      if (uploadResult?.type === "error") {
        setError(uploadResult.message);
        return;
      }

      const updateResult = await updateFile(
        signedUrlResult.value.version,
        token,
      );
      if (updateResult?.type === "error") {
        setError(updateResult.message);
        return;
      }
      setUrl(updateResult.value.url);
      onFileUploaded?.(type.id);
    } finally {
      setBusy(false);
      setProgress(null);
      onFileUploadEnded?.(type.id);
    }
  };

  return (
    <UserFileAsset
      type={type}
      error={error ?? undefined}
      url={url ?? undefined}
      progress={progress ?? undefined}
      minimal={false}
      action={
        edit !== "disabled" ? (
          <>
            <input
              accept={accept}
              hidden
              id={`${type.id}-button-file`}
              data-testid={`${type.id}-button-file`}
              type="file"
              onChange={onFileSelected}
              disabled={busy}
            />
            <label htmlFor={`${type.id}-button-file`}>
              <Button
                size="small"
                color="primary"
                component="span"
                disabled={busy}
              >
                {busy ? (
                  <LoadingIndicator transitionDelay={0} size={26} />
                ) : (
                  "Upload"
                )}
              </Button>
            </label>
          </>
        ) : undefined
      }
    />
  );
}
