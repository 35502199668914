import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth0, useProfile } from "../../auth0";
import * as Sentry from "@sentry/react";
import { makeStyles } from "tss-react/mui";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AxiosError } from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessDeniedPanel from "../../components/AccessDeniedPanel";
import { isTimeoutError } from "../../api";
import { useOwnTalentProfile } from "../../OwnTalentProfile";

const useStyles = makeStyles()((theme) => ({
  icon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

export default function ProfilePaymentConfirmation(): JSX.Element {
  const { classes } = useStyles();
  const { isAuthenticated } = useAuth0();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);
  const [ownTalentProfile, setOwnTalentProfile] = useOwnTalentProfile();

  const hasAccess = isAuthenticated && profile !== undefined;

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function markAsPaid() {
      if (ownTalentProfile === null || ownTalentProfile instanceof Error) {
        setError("Talent profile was not found");
        return;
      }

      if (ownTalentProfile === undefined) {
        return;
      }

      if (!ownTalentProfile.isInitialSetupComplete) {
        setError("Talent profile cannot be marked as paid right now");
        Sentry.captureMessage(
          "Talent profile is not complete, and yet someone tried to confirm the payment",
          (scope) =>
            scope.addBreadcrumb({
              level: "warning",
              data: {
                talentProfile: {
                  user: {
                    id: ownTalentProfile.user.id,
                    email: ownTalentProfile.user.email,
                  },
                  id: ownTalentProfile.id,
                },
              },
            }),
        );
        return;
      }

      try {
        const paidAt = new Date().toISOString();

        await setOwnTalentProfile({
          id: ownTalentProfile.id,
          paidAt: { value: paidAt },
          transientState: "confirmed-payment-redirect",
        });

        setConfirmed(true);
      } catch (error) {
        if (isTimeoutError(error)) {
          setError(
            "Request to confirm the payment status failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          setError(
            "Unexpected error occurred when confirming the payment status",
          );
        }
        const err = error as AxiosError;
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message:
              "Unexpected error occurred during profile payment update API call",
            data: {
              talentProfile: {
                user: {
                  id: ownTalentProfile.user.id,
                  email: ownTalentProfile.user.email,
                },
                id: ownTalentProfile.id,
              },
              response: {
                status: err.response?.status,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                data: err.response?.data,
              },
            },
          }),
        );
        return;
      }
    }

    if (confirmed || !hasAccess) {
      return;
    }

    void markAsPaid();
  }, [ownTalentProfile]);

  useEffect(() => {
    if (!confirmed) {
      return;
    }
    const handle = setTimeout(() => {
      navigate("/news");
    }, 2500);
    return () => clearTimeout(handle);
  }, [confirmed, navigate]);

  if (!hasAccess) {
    return <AccessDeniedPanel />;
  }

  if (error !== null) {
    return (
      <div className={classes.root}>
        <ErrorOutlineIcon
          fontSize="large"
          className={classes.icon}
          color="error"
        />
        <Typography variant="body1">Payment could not be confirmed</Typography>
        <Typography variant="body2" component="p">
          {error}
        </Typography>
      </div>
    );
  }

  if (confirmed) {
    return (
      <div className={classes.root}>
        <CheckCircleOutlineIcon
          fontSize="large"
          className={classes.icon}
          color="primary"
        />
        <Typography variant="body1">
          Payment confirmed! You're all set
        </Typography>
        <Typography variant="body2" component="p">
          You should be automatically redirected in a few seconds
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <HourglassEmptyIcon fontSize="large" className={classes.icon} />
      <Typography variant="body1">
        Please wait, confirming the payment...
      </Typography>
    </div>
  );
}
