import { components } from "./api/schema/v1";

class Claim {
  public static readonly NAMESPACE = "https://smythcasting.co/permissions/";

  public constructor(
    public readonly group: string,
    public readonly name: string,
  ) {}

  public toString() {
    return `${Claim.NAMESPACE}${this.group}:${this.name}`;
  }

  public equals(claimType: components["schemas"]["ClaimType"] | undefined) {
    return (
      claimType?.name === this.name && claimType?.group?.name === this.group
    );
  }

  public createTestClaim(value?: string): components["schemas"]["Claim"] {
    return {
      id: 1,
      value,
      createdAt: "2021-01-20:12:13:14Z",
      type: {
        id: 1,
        description: "Type description",
        group: {
          name: this.group,
          description: "Group description",
          id: 1,
        },
        name: this.name,
        isRestricted: true,
      },
    };
  }
}

export const userClaims = Object.freeze({
  systemAdministration: new Claim("user", "system:administration"),
  details: new Claim("user", "details:read"),
});
