import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.appBar + 1,
    color: "#fff",
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  message: {
    color: theme.palette.primary.dark,
  },
  backdropMessage: {
    color: theme.palette.common.white,
  },
  centered: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function LoadingIndicator(props: {
  message?: string | null;
  backdrop?: boolean;
  fadeTimeout?: number;
  transitionDelay?: number;
  size?: number;
}): JSX.Element {
  const {
    message,
    backdrop = false,
    fadeTimeout,
    size,
    transitionDelay = 500,
  } = props;
  const { classes } = useStyles();
  const loader = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box className={classes.centered}>
          <CircularProgress
            className={classes.spinner}
            size={size}
            data-testid="spinner-circular"
          />
        </Box>
      </Grid>
      {message ? (
        <Grid item xs={12}>
          <Box className={classes.centered}>
            <Typography
              className={backdrop ? classes.backdropMessage : classes.message}
              variant="caption"
              component="div"
            >
              {message}
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );

  const wrappedLoader = backdrop ? (
    <Backdrop
      open={true}
      className={classes.backdrop}
      data-testid="spinner-backdrop"
    >
      {loader}
    </Backdrop>
  ) : (
    loader
  );

  return (
    <Fade
      in
      timeout={fadeTimeout}
      style={{
        transitionDelay: `${transitionDelay}ms`,
      }}
      unmountOnExit
    >
      {wrappedLoader}
    </Fade>
  );
}
