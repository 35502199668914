import { makeStyles } from "tss-react/mui";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0, useProfile } from "../../auth0";
import { userClaims } from "../../claims";
import { getProfile, UserProfile as User } from "./api";
import AccessDeniedPanel from "../../components/AccessDeniedPanel";
import * as Sentry from "@sentry/react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Profile from "./components/Profile";
import WarningIcon from "@mui/icons-material/Warning";
import { isTimeoutError } from "../../api";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
  },
  search: {
    marginBottom: theme.spacing(2),
  },
  errorIcon: {
    height: theme.spacing(8),
    color: theme.palette.error.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  warningIcon: {
    height: theme.spacing(8),
    color: theme.palette.warning.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  errorPanel: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rootSkeleton: {
    padding: theme.spacing(5),
  },
}));

export default function UserProfile(): JSX.Element {
  const { classes } = useStyles();
  const { id } = useParams<{ id: string }>();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { profile } = useProfile();
  const [busy, setBusy] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);

  const [apiError, setApiError] = useState<string | null>(null);

  const hasAccess =
    isAuthenticated &&
    id !== undefined &&
    profile?.claims?.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) === true;

  const getUserProfile = useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      setBusy(true);
      const token = await getAccessTokenSilently();
      const profile = await getProfile({ id }, { token });
      setUser(profile);
    } catch (err) {
      const error = err as AxiosError;
      if (isTimeoutError(err)) {
        setApiError(
          "Request to retrieve the user profile failed due to network connectivity issues, please refresh and try again.",
        );
      } else {
        const ALLOWED_STATUS_CODES = [404, 403, 401];
        if (ALLOWED_STATUS_CODES.includes(error.response?.status ?? -1)) {
          setApiError(null);
          setUser(null);
          return;
        }
        setApiError(
          "Unable to retrieve the user profile. Please try again later.",
        );
      }
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to fetch the user profile",
          data: {
            id,
          },
        }),
      );
    } finally {
      setBusy(false);
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      return;
    }
    void getUserProfile();
  }, [getUserProfile, user]);

  if (!hasAccess) {
    return <AccessDeniedPanel />;
  }

  if (busy) {
    return (
      <Container className={classes.rootSkeleton} maxWidth="xs">
        <Box display="flex" alignItems="center" width="100%">
          <Box margin={1}>
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          </Box>
          <Box width="100%">
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          </Box>
        </Box>
        <Skeleton variant="rectangular" width="100%">
          <div style={{ paddingTop: "57%" }} />
        </Skeleton>
      </Container>
    );
  }

  if (apiError) {
    return (
      <div className={classes.errorPanel}>
        <ErrorOutlineIcon fontSize="large" className={classes.errorIcon} />
        <Typography variant="body1">{apiError}</Typography>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={classes.errorPanel}>
        <WarningIcon fontSize="large" className={classes.warningIcon} />
        <Typography variant="h5">404: User not found</Typography>
        <Typography variant="body1">
          Selected user profile could not be found
        </Typography>
      </div>
    );
  }

  return <Profile editable={true} user={user} />;
}
