import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";
import AccessDeniedPanel from "../../../components/AccessDeniedPanel";
import { RestrictedTalentProfileData } from "../api";
import TalentProfileForm from "./TalentProfileForm";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useProfile } from "../../../auth0";
import LoadingIndicator from "../../../components/LoadingIndicator";
import * as Sentry from "@sentry/react";
import { FileListing } from "../file/File";
import Gallery from "../file/Gallery";
import { FileType } from "../file/api";
import { isTimeoutError } from "../../../api";
import TemporarySnackbar from "../../../components/TemporarySnackbar";
import { TransientState, useOwnTalentProfile } from "../../../OwnTalentProfile";
import { SHOPIFY_REDIRECT_URL } from "../../../config";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  stepper: {
    marginBottom: theme.spacing(5),
  },
  stepperIndicator: {
    padding: theme.spacing(3),
  },
  navigationButton: {
    color: theme.palette.primary.dark,
  },
  stepperNavigationGrid: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(1),
  },
  callToActionButton: {
    marginTop: theme.spacing(2),
  },
}));

export default function TalentProfileStepperPanel(props: {
  talentProfile: RestrictedTalentProfileData | null;
  fileListing: FileListing;
  location?: Location;
  onFileUpload: (type: FileType) => void;
}): JSX.Element {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const { profile } = useProfile();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { talentProfile, location, fileListing, onFileUpload } = props;
  const [, setOwnTalentProfile] = useOwnTalentProfile();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(
    talentProfile === null ? 0 : 1,
  );
  const hasRequiredFiles =
    fileListing.files?.some((f) => f.type.id === "headshot") ?? false;

  function canMoveToNextStep() {
    switch (activeStepIndex) {
      case 0:
        return talentProfile !== null;
      case 1:
        return hasRequiredFiles;
      default:
        return false;
    }
  }

  const moveToNextStep = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const moveToPreviousStep = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const completeProfileSetUp = async (redirect: TransientState) => {
    if (talentProfile === null) {
      Sentry.captureMessage(
        "Trying to complete the profile set up with the profile still missing",
        (scope) => scope.setLevel("error"),
      );
      setErrorMessage(
        "Cannot complete the profile set up with missing talent profile",
      );
      return false;
    }

    setBusy(true);

    try {
      await setOwnTalentProfile({
        id: talentProfile.id,
        isInitialSetupComplete: true,
        transientState: redirect,
      });
    } catch (error) {
      if (isTimeoutError(error)) {
        setErrorMessage(
          "Request to complete the user profile failed due to network connectivity issues, please try again",
        );
      } else {
        setErrorMessage("Could not complete the user profile at this time");
      }
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to complete the user talent profile",
          data: {
            profileId: talentProfile.id,
          },
        }),
      );
      return false;
    } finally {
      setBusy(false);
    }

    return true;
  };

  const completeWithoutPayment = async () => {
    if (!(await completeProfileSetUp("union-profile-redirect"))) {
      return;
    }

    navigate("/");
  };

  const goToPayment = async () => {
    if (profile === null || talentProfile === null) {
      setErrorMessage(
        "Cannot go to payment - either the user profile or the talent profile are missing",
      );
      Sentry.captureMessage(
        "Cannot go to payment - either the user profile or the talent profile are missing",
        (scope) =>
          scope.addBreadcrumb({
            level: "error",
            data: {
              profileId: talentProfile?.id,
              userId: profile?.id,
            },
          }),
      );
      return;
    }
    if (!(await completeProfileSetUp("pending-payment-redirect"))) {
      return;
    }
    (location ?? window.location).replace(SHOPIFY_REDIRECT_URL);
  };

  function getStepperContents() {
    switch (activeStepIndex) {
      case 0:
        return (
          <TalentProfileForm
            profile={talentProfile ?? undefined}
            requireConsent={talentProfile === null}
            cancellable={false}
          />
        );
      case 1:
        return (
          <Gallery
            access="own-files"
            talentProfile={talentProfile ?? undefined}
            listing={fileListing}
            onUpload={onFileUpload}
          />
        );
      case 2: {
        if ((talentProfile?.unionStatuses?.length ?? 0) > 0) {
          return (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Grid item xs>
                <Typography variant="h4">You're all set!</Typography>
              </Grid>
              <Grid item xs>
                <Button
                  className={classes.callToActionButton}
                  variant="outlined"
                  color="primary"
                  onClick={completeWithoutPayment}
                >
                  Complete the registration
                </Button>
              </Grid>
            </Grid>
          );
        }
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item xs>
              <Typography variant="h4">Payment required</Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                Payment is required to complete the talent profile registration
              </Typography>
            </Grid>
            <Grid item xs>
              <Button
                className={classes.callToActionButton}
                variant="outlined"
                color="primary"
                onClick={goToPayment}
              >
                Pay now
              </Button>
            </Grid>
          </Grid>
        );
      }
      default:
        return <AccessDeniedPanel />;
    }
  }

  return (
    <Container>
      <Paper className={classes.stepper}>
        <Stepper
          activeStep={activeStepIndex}
          className={classes.stepperIndicator}
        >
          <Step completed={talentProfile !== null}>
            <StepLabel>Profile</StepLabel>
          </Step>
          <Step completed={hasRequiredFiles}>
            <StepLabel>Gallery</StepLabel>
          </Step>
          <Step>
            <StepLabel>Complete registration</StepLabel>
          </Step>
        </Stepper>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={classes.stepperNavigationGrid}
        >
          <Grid item xs>
            <Button
              disabled={activeStepIndex === 0}
              onClick={moveToPreviousStep}
              size="small"
              variant="text"
              aria-label="previous step"
              className={classes.navigationButton}
              startIcon={<ArrowBackIosIcon />}
            >
              Previous
            </Button>
          </Grid>
          <Grid container item xs justifyContent="flex-end">
            <Button
              onClick={moveToNextStep}
              disabled={!canMoveToNextStep()}
              size="small"
              variant="text"
              aria-label="next step"
              className={classes.navigationButton}
              endIcon={<ArrowForwardIosIcon />}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div aria-label="wizard content">{getStepperContents()}</div>
      {busy ? <LoadingIndicator backdrop /> : null}
      <TemporarySnackbar
        message={errorMessage}
        clearMessage={setErrorMessage}
      />
    </Container>
  );
}
