import React, { useState } from "react";
import { useAuth0, useProfile } from "../../../auth0";
import {
  TalentProfileCreationRequest,
  RestrictedTalentProfileData,
  updateTalentProfile,
} from "../api";
import { Controller, useForm, Validate } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MuiLink from "@mui/material/Link";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TOS_LINK } from "../../../config";
import TemporarySnackbar from "../../../components/TemporarySnackbar";
import Input from "@mui/material/Input";
import Chip from "@mui/material/Chip";
import { ListItemText, useMediaQuery } from "@mui/material";
import { useOwnTalentProfile } from "../../../OwnTalentProfile";
import { useLookups } from "../../../Lookup";
import ApiErrorPanel from "../../../components/ApiErrorPanel";
import { AxiosError } from "axios";
import {
  getFirstValidationError,
  ValidationProblemDetails,
} from "../../../api/validation";
import * as Sentry from "@sentry/react";
import { SelectInputProps } from "@mui/material/Select/SelectInput";

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
  },
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formLayout: {
    flexGrow: 1,
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ONLY_DIGITS = /^\d+$/;

export default function TalentProfileForm(props: {
  profile?: RestrictedTalentProfileData;
  cancellable?: boolean;
  requireConsent?: boolean;
  onCancel?: () => void;
  onComplete?: () => void;
}): JSX.Element {
  const { classes } = useStyles();
  const {
    profile: talentProfile,
    onCancel,
    requireConsent = false,
    onComplete,
    cancellable = true,
  } = props;
  const navigate = useNavigate();

  const theme = useTheme();
  const testScreenSize = useMediaQuery(theme.breakpoints.up("sm"))
    ? `grid-full`
    : `grid-small`;

  const { getAccessTokenSilently } = useAuth0();
  const [, setOwnTalentProfile] = useOwnTalentProfile();
  const { profile } = useProfile();
  const { isLoading, lookups } = useLookups();
  const [busy, setBusy] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(!requireConsent);
  const [apiError, setApiError] = useState<string | null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TalentProfileCreationRequest>({
    criteriaMode: "all",
    defaultValues: {
      firstName: talentProfile?.firstName ?? "",
      lastName: talentProfile?.lastName ?? "",
      phone: talentProfile?.phone ?? "",
      birthDate: talentProfile?.birthDate ?? "",
      allergies: talentProfile?.allergies ?? "",
      medicalConditions: talentProfile?.medicalConditions ?? "",
      height: talentProfile?.height ?? ("" as unknown as number),
      weight: talentProfile?.weight ?? ("" as unknown as number),
      provinceOfResidence:
        (talentProfile?.provinceOfResidence?.id?.toString() ??
          "") as unknown as number,
      gender: (talentProfile?.gender?.id?.toString() ??
        "") as unknown as number,
      hairColor: (talentProfile?.hairColor?.id?.toString() ??
        "") as unknown as number,
      eyeColor: (talentProfile?.eyeColor?.id?.toString() ??
        "") as unknown as number,
      ethnicity: (talentProfile?.ethnicity?.id?.toString() ??
        "") as unknown as number,
      unionStatuses:
        talentProfile?.unionStatuses?.map((unionStatus) => unionStatus.id) ??
        [],
      languages: talentProfile?.languages?.map((language) => language.id) ?? [],
    },
  });

  const nonFractional: Validate<number | string, unknown> = (
    value: number | string,
  ) => {
    const error = "Value cannot have a decimal point";
    if (typeof value === "number" && !Number.isInteger(value)) {
      return error;
    }
    if (typeof value === "string" && !ONLY_DIGITS.test(value)) {
      return error;
    }
    return true;
  };

  async function saveProfileData(data: TalentProfileCreationRequest) {
    setBusy(true);
    try {
      const token = await getAccessTokenSilently();
      if (talentProfile !== undefined) {
        const request = {
          ...data,
          medicalConditions: {
            value: data.medicalConditions,
          },
          phone: {
            value: data.phone,
          },
          unionStatuses: {
            value: data.unionStatuses,
          },
          allergies: {
            value: data.allergies,
          },
          languages: {
            value: data.languages,
          },
        };
        if (talentProfile.user.id !== profile?.id) {
          await updateTalentProfile(talentProfile.id, request, {
            token,
          });
        } else {
          await setOwnTalentProfile({ ...request, id: talentProfile.id });
        }
      } else {
        await setOwnTalentProfile(data);
      }

      if (onComplete) {
        onComplete();
        return;
      }
      navigate("/talent/profile/self", { replace: true });
    } catch (err) {
      const error = err as AxiosError;
      if (!error.isAxiosError) {
        setApiError("Failed to save the form, please try again...");
        Sentry.captureException(err, (scope) =>
          scope.addBreadcrumb({
            message: "Non-axios error occurred on talent profile editing",
            data: {
              talentProfileId: talentProfile?.id,
              type: talentProfile === undefined ? "creation" : "update",
              user: profile?.id,
            },
          }),
        );
        return;
      }

      switch (error.response?.status) {
        case 400: {
          const validationError = getFirstValidationError(
            error as AxiosError<ValidationProblemDetails>,
          );
          setApiError(validationError.message);
          if (validationError.status === "default") {
            Sentry.captureException(err, (scope) =>
              scope.addBreadcrumb({
                message: "Unhandled validation error on talent profile edit",
                data: {
                  type: talentProfile === undefined ? "creation" : "update",
                  user: profile?.id,
                  talentProfileId: talentProfile?.id,
                  response: error.response,
                },
              }),
            );
          }
          return;
        }
        default: {
          setApiError("Failed to save the form, please try again...");
          Sentry.captureException(err, (scope) =>
            scope.addBreadcrumb({
              message: "Error occurred on talent profile edit",
              data: {
                user: profile?.id,
                talentProfileId: talentProfile?.id,
                type: talentProfile === undefined ? "creation" : "update",
                status: error.response?.status,
              },
            }),
          );
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    } finally {
      setBusy(false);
    }
  }

  if (lookups instanceof Error) {
    return <ApiErrorPanel message={lookups.message} />;
  }

  return (
    <Paper className={classes.root} data-testid={testScreenSize}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit((formData) => {
          if (!submitEnabled) {
            return;
          }
          const {
            allergies,
            medicalConditions,
            unionStatuses,
            phone,
            ...rest
          } = formData;
          return saveProfileData({
            ...rest,
            allergies: allergies === "" ? undefined : allergies,
            medicalConditions:
              medicalConditions === "" ? undefined : medicalConditions,
            phone: phone === "" ? undefined : phone,
            unionStatuses,
          });
        })}
      >
        <Grid container spacing={3} className={classes.formLayout}>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="First name"
                    error={errors.firstName !== undefined}
                    helperText={errors.firstName?.message}
                    variant="standard"
                  />
                )}
                name="firstName"
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last name"
                    error={errors.lastName !== undefined}
                    helperText={errors.lastName?.message}
                    variant="standard"
                  />
                )}
                name="lastName"
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field is required",
                  minLength: {
                    value: 1,
                    message:
                      "Field length has to be greater than or equal to 1",
                  },
                  maxLength: {
                    value: 200,
                    message: "Field length has to be less than or equal to 200",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    type="tel"
                    error={errors.phone !== undefined}
                    helperText={errors.phone?.message}
                    variant="standard"
                  />
                )}
                name="phone"
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Date of birth"
                    type="date"
                    error={errors.birthDate !== undefined}
                    InputLabelProps={{ shrink: true, required: false }}
                    helperText={errors.birthDate?.message}
                    variant="standard"
                  />
                )}
                name="birthDate"
                control={control}
              />
            </FormControl>
            <Controller
              rules={{
                required: "This field is required",
              }}
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="province-of-residence-label"
                    error={errors.provinceOfResidence !== undefined}
                    variant="standard"
                  >
                    Province of residence
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="province-of-residence-select"
                    labelId="province-of-residence-label"
                    variant="standard"
                  >
                    {lookups?.provinces.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.provinceOfResidence ? (
                    <FormHelperText error variant="standard">
                      {errors.provinceOfResidence.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="provinceOfResidence"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              rules={{
                required: "This field is required",
              }}
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="gender-label"
                    error={errors.gender !== undefined}
                    variant="standard"
                  >
                    Gender
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="gender-select"
                    labelId="gender-label"
                    variant="standard"
                  >
                    {lookups?.genders.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.gender ? (
                    <FormHelperText error variant="standard">
                      {errors.gender.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="gender"
              control={control}
            />
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field must be a number and is required",
                  min: {
                    message: "Value must be greater than 0",
                    value: 1,
                  },
                  max: {
                    message: "Value must be less than or equal to 300",
                    value: 300,
                  },
                  validate: {
                    nonFractional,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Height (cm)"
                    type="number"
                    id="height"
                    InputLabelProps={{ shrink: true, required: false }}
                    error={errors.height !== undefined}
                    helperText={errors.height?.message}
                    variant="standard"
                  />
                )}
                name="height"
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Controller
                rules={{
                  required: "This field must be a number and is required",
                  min: {
                    message: "Value must be greater than 0",
                    value: 1,
                  },
                  max: {
                    message: "Value must be less than or equal to 1000",
                    value: 1000,
                  },
                  validate: {
                    nonFractional,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Weight (lb)"
                    type="number"
                    id="weight"
                    InputLabelProps={{ shrink: true, required: false }}
                    error={errors.weight !== undefined}
                    helperText={errors.weight?.message}
                    variant="standard"
                  />
                )}
                name="weight"
                control={control}
              />
            </FormControl>
            <Controller
              rules={{
                required: "This field is required",
              }}
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="ethnicity-label"
                    error={errors.ethnicity !== undefined}
                    variant="standard"
                  >
                    Ethnicity
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="ethnicity-select"
                    labelId="ethnicity-label"
                    variant="standard"
                  >
                    {lookups?.ethnicities.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.ethnicity ? (
                    <FormHelperText error variant="standard">
                      {errors.ethnicity.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="ethnicity"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="language-label"
                    error={errors.languages !== undefined}
                    variant="standard"
                  >
                    Languages
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="language-select"
                    labelId="language-label"
                    multiple
                    input={<Input id="languages" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as number[]).map((value) => (
                          <Chip
                            key={value}
                            label={
                              lookups?.languages?.find((x) => x.id === value)
                                ?.name ?? "N/A"
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {lookups?.languages.map((l) => (
                      <MenuItem key={l.id} value={l.id}>
                        <Checkbox
                          checked={field.value?.includes(l.id) || false}
                          color="secondary"
                        />
                        <ListItemText primary={l.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.languages ? (
                    <FormHelperText error>
                      {(Array.isArray(errors.languages)
                        ? errors.languages
                        : [errors.languages]
                      ).map((e, ix) => (
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        <li key={ix}>{e.message}</li>
                      ))}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="languages"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              rules={{
                required: "This field is required",
              }}
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="hair-color-label"
                    error={errors.hairColor !== undefined}
                    variant="standard"
                  >
                    Hair color
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="hair-color-select"
                    labelId="hair-color-label"
                    variant="standard"
                  >
                    {lookups?.hairColors.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.hairColor ? (
                    <FormHelperText error variant="standard">
                      {errors.hairColor.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="hairColor"
              control={control}
            />
            <FormControl className={classes.formControl}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Allergies"
                    error={errors.allergies !== undefined}
                    helperText={errors.allergies?.message}
                    variant="standard"
                  />
                )}
                name="allergies"
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Medical conditions"
                    error={errors.medicalConditions !== undefined}
                    helperText={errors.medicalConditions?.message}
                    variant="standard"
                  />
                )}
                name="medicalConditions"
                control={control}
              />
            </FormControl>
            <Controller
              rules={{
                required: "This field is required",
              }}
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="eye-color-label"
                    error={errors.eyeColor !== undefined}
                    variant="standard"
                  >
                    Eye color
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="eye-color-select"
                    labelId="eye-color-label"
                    variant="standard"
                  >
                    {lookups?.eyeColors.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.eyeColor ? (
                    <FormHelperText error variant="standard">
                      {errors.eyeColor.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="eyeColor"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="union-statuses-label"
                    error={errors.unionStatuses !== undefined}
                    variant="standard"
                  >
                    Union Statuses
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={
                      field.onChange as unknown as SelectInputProps<unknown>["onChange"]
                    }
                    id="union-statuses-select"
                    labelId="union-statuses-label"
                    multiple
                    input={<Input id="unionStatuses" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {(selected as number[]).map((value) => (
                          <Chip
                            key={value}
                            label={
                              lookups?.unionStatuses?.find(
                                (x) => x.id === value,
                              )?.name ?? "N/A"
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {lookups?.unionStatuses.map((u) => (
                      <MenuItem key={u.id} value={u.id}>
                        <Checkbox
                          checked={field.value?.includes(u.id) || false}
                          color="secondary"
                        />
                        <ListItemText primary={u.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.unionStatuses ? (
                    <FormHelperText error>
                      {(Array.isArray(errors.unionStatuses)
                        ? errors.unionStatuses
                        : [errors.unionStatuses]
                      ).map((e, ix) => (
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        <li key={ix}>{e.message}</li>
                      ))}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
              name="unionStatuses"
              control={control}
            />
          </Grid>
        </Grid>
        {requireConsent ? (
          <Grid item xs>
            <Box display="flex" alignItems="center">
              <Checkbox
                color="secondary"
                aria-label="Consent checkbox"
                onChange={(event) => {
                  setSubmitEnabled(event.target.checked);
                }}
              />
              <Typography>
                I consent to the platform's{" "}
                <MuiLink
                  href={TOS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </MuiLink>
              </Typography>
            </Box>
          </Grid>
        ) : null}
        <Grid item xs>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={!submitEnabled}
            className={classes.actionButton}
          >
            Save
          </Button>
          {talentProfile !== undefined && cancellable ? (
            onCancel ? (
              <Button
                color="secondary"
                onClick={onCancel}
                className={classes.actionButton}
              >
                Cancel
              </Button>
            ) : (
              <MuiLink component={Link} to="/talent/profile/self">
                <Button color="secondary" className={classes.actionButton}>
                  Cancel
                </Button>
              </MuiLink>
            )
          ) : null}
        </Grid>
      </form>
      <TemporarySnackbar message={apiError} clearMessage={setApiError} />
      {busy || isLoading ? <LoadingIndicator backdrop={true} /> : null}
    </Paper>
  );
}
