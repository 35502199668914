import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useProfile } from "../../../auth0";
import { userClaims } from "../../../claims";
import AccessDeniedPanel from "../../../components/AccessDeniedPanel";
import { FileType } from "../file/api";
import { FileListing } from "../file/File";
import Gallery from "../file/Gallery";
import AccountInformation, {
  AccountDataChangeCallback,
} from "./account-information";
import { TalentProfile } from "./TalentProfile";
import TalentProfileDisplay from "./TalentProfileDisplay";
import TalentProfileForm from "./TalentProfileForm";

function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (value === index) {
      navigate({
        search: new URLSearchParams({
          t: index.toString(),
        }).toString(),
      });
    }
  }, [value, index, navigate]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`talent-profile-tabpanel-${index}`}
      aria-labelledby={`talent-profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function TalentProfileTabPanel(props: {
  talentProfile: TalentProfile;
  fileListing: FileListing;
  mode: "display" | "editor";
  onModeChange: (mode: "display" | "editor") => void;
  onAccountDataChange?: AccountDataChangeCallback;
  onFileUpload?: (type: FileType) => void;
}): JSX.Element {
  const {
    talentProfile,
    onAccountDataChange,
    fileListing,
    onFileUpload,
    onModeChange,
    mode,
  } = props;
  const { profile } = useProfile();
  const elevated =
    profile?.claims.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) ?? false;
  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const index = Number.parseInt(qs.get("t") ?? "");
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number.isNaN(index) ? 0 : index,
  );
  if (
    profile?.claims?.some((c) => userClaims.details.equals(c.type)) !== true
  ) {
    return <AccessDeniedPanel />;
  }
  return (
    <div>
      <Container>
        <Tabs
          value={activeTabIndex}
          onChange={(_, newValue) => setActiveTabIndex(newValue as number)}
        >
          <Tab label="Profile" />
          <Tab label="Gallery" />
          {elevated ? <Tab label="Admin" /> : null}
        </Tabs>
      </Container>
      <Fragment>
        <TabPanel value={activeTabIndex} index={0}>
          {elevated && talentProfile.type === "restricted" ? (
            <Container>
              {mode === "editor" ? (
                <TalentProfileForm
                  profile={talentProfile.data ?? undefined}
                  onCancel={() => onModeChange("display")}
                  onComplete={() => onModeChange("display")}
                />
              ) : (
                <TalentProfileDisplay
                  profile={talentProfile}
                  files={fileListing.files}
                  onEditClick={() => onModeChange("editor")}
                />
              )}
            </Container>
          ) : (
            <Container>
              <TalentProfileDisplay
                profile={talentProfile}
                files={fileListing.files}
              />
            </Container>
          )}
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <Gallery
            talentProfile={talentProfile.data}
            listing={fileListing}
            access="other-user"
            onUpload={onFileUpload}
          />
        </TabPanel>
        {elevated && talentProfile.type === "restricted" ? (
          <TabPanel value={activeTabIndex} index={2}>
            <AccountInformation
              talentProfile={talentProfile.data}
              elevated={elevated}
              onChange={onAccountDataChange}
            />
          </TabPanel>
        ) : null}
      </Fragment>
    </div>
  );
}
