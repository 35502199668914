import axios from "axios";
import { TOTAL_COUNT_HEADER, Paged, RequestOptions, settings } from "../../api";
import { paths, components } from "../../api/schema/v1";
import { tryParseInt } from "../../parsing";

const listingPath: keyof paths = "/user/profile";
const contentType = "application/json;v=1";
type ProfileListRequest =
  paths[typeof listingPath]["get"]["parameters"]["query"];

export type ProfileListResponse =
  paths[typeof listingPath]["get"]["responses"]["200"]["content"][typeof contentType];
export type ProfileListItem = components["schemas"]["BasicUserProfile"];

export async function getProfileListing(
  request: ProfileListRequest,
  options: RequestOptions,
): Promise<Paged<ProfileListResponse>> {
  const { token } = options;
  const response = await axios.get<ProfileListResponse>(
    `${settings.url}${listingPath}`,
    {
      headers: {
        Accept: contentType,
        Authorization: `Bearer ${token}`,
      },
      params: request,
      timeout: 10_000,
    },
  );
  const totalCountHeader = (
    response.headers as Record<string, string | undefined>
  )[TOTAL_COUNT_HEADER];
  return {
    data: response.data,
    totalCount: tryParseInt(totalCountHeader) ?? 0,
  };
}
