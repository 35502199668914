import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_PAGE_SIZE } from "../api";
import { tryParseInt } from "../parsing";

export type PageInfo = {
  page: number;
  size: number;
};

export default function ListingPagination(props: {
  className?: string;
  total: number;
  defaultPageSize?: number;
  onPageChange?: (event: PageInfo) => void;
}): JSX.Element {
  const {
    className,
    total,
    onPageChange,
    defaultPageSize = DEFAULT_PAGE_SIZE,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const qs = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const [page, setPage] = useState<PageInfo>({
    page: tryParseInt(qs.get("page")) ?? 1,
    size: tryParseInt(qs.get("limit")) ?? defaultPageSize,
  });
  const pageCount = Math.ceil(total / page.size);

  useEffect(() => {
    if (page.page > 1) {
      qs.set("page", page.page.toString());
    } else {
      qs.delete("page");
    }

    qs.set("limit", page.size.toString());
    navigate({ search: qs.toString() });
    onPageChange?.(page);
  }, [page, qs]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={className}
    >
      <Pagination
        count={pageCount}
        page={page.page}
        onChange={(_, nextPage) => {
          setPage((prev) => ({ ...prev, page: nextPage }));
        }}
      />
    </Box>
  );
}
