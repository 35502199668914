import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import BasicUserData, {
  UserChangeData,
} from "../../../../user/components/BasicUserData";
import { RestrictedTalentProfileData } from "../../api";
import PaymentInfoEditPanel, {
  PaymentDataChange,
} from "./PaymentInfoEditPanel";

export type AccountChangeData =
  | { type: "account"; value: UserChangeData }
  | { type: "payment"; value: PaymentDataChange };
export type AccountDataChangeCallback = (
  data: AccountChangeData,
) => void | Promise<void>;

export default function AccountInformation(props: {
  talentProfile: RestrictedTalentProfileData;
  elevated: boolean;
  onChange?: AccountDataChangeCallback;
}): JSX.Element {
  const { talentProfile, elevated, onChange } = props;
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md>
          <BasicUserData
            user={talentProfile.user}
            editable={elevated}
            onChange={(data) => onChange?.({ type: "account", value: data })}
          />
        </Grid>
        <Grid item md>
          <PaymentInfoEditPanel
            onChange={(data) => onChange?.({ type: "payment", value: data })}
            paymentData={{
              id: talentProfile.id,
              paidAt: talentProfile.paidAt,
              union: talentProfile.unionStatuses?.length > 0 ?? false,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
