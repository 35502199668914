import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import { SHOPIFY_REDIRECT_URL } from "../../config";

export default function RenewSubscriptionDialog(props: {
  location?: Location;
}): JSX.Element {
  const { location = window.location } = props;
  const goToPayment = () => {
    location.replace(SHOPIFY_REDIRECT_URL);
  };

  return (
    <Dialog open={true} aria-labelledby="renew-subscription-dialog-title">
      <DialogTitle id="renew-subscription-dialog-title">
        Subscription expired
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To continue using SmythCasting, please renew your subscription by
          clicking the button below
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={goToPayment}>
          Renew now
        </Button>
      </DialogActions>
    </Dialog>
  );
}
