import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAuth0 } from "./auth0";
import { OAuthError } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AccountBlocked from "./AccountBlocked";

const useStyles = makeStyles()((theme) => ({
  error: {
    height: theme.spacing(8),
    color: theme.palette.error.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

export default function RequireAuthentication({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const err = error as OAuthError;
  const isBlocked =
    err?.error === "unauthorized" &&
    err?.error_description === "user is blocked";

  useEffect(() => {
    if (isBlocked) {
      navigate("/blocked", { replace: true });
      return;
    }

    if (isLoading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `${location.pathname}${location.search}`,
        },
      });
    };
    void fn();
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    isBlocked,
    location,
    navigate,
  ]);

  if (isBlocked) {
    return <AccountBlocked />;
  }

  if (!isAuthenticated) {
    return (
      <div className={classes.root}>
        <Typography variant="subtitle1">
          Redirecting to log in screen, please wait...
        </Typography>
      </div>
    );
  }

  return children;
}
