import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  IdToken,
  Auth0Provider,
  Auth0ContextInterface,
  Auth0ProviderOptions,
  useAuth0,
  Auth0Context,
} from "@auth0/auth0-react";
import { getOwnProfile, OwnUserProfile } from "./user/profile/api";
import { setUser as setSentryUser } from "@sentry/react";

if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error("REACT_APP_AUTH0_DOMAIN environmental variable is missing");
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error(
    "REACT_APP_AUTH0_CLIENT_ID environmental variable is missing",
  );
}

if (!process.env.REACT_APP_AUTH0_API_IDENTIFIER) {
  throw new Error(
    "REACT_APP_AUTH0_API_IDENTIFIER environmental variable is missing",
  );
}

export const settings = Object.freeze({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  apiIdentifier: process.env.REACT_APP_AUTH0_API_IDENTIFIER,
});

export const CLAIM_NAMESPACE = "https://smythcasting.co/";

export function getCustomClaim<T>(
  token: IdToken | undefined | null,
  name: string,
): T | undefined {
  if (!token) {
    return;
  }
  return token[`${CLAIM_NAMESPACE}${name}`] as T | undefined;
}

export { Auth0Provider, useAuth0 };

type ProfileContextType = {
  profile: OwnUserProfile | null;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: null,
});

type ProfileContextOptions = {
  profile?: OwnUserProfile;
};

export const useProfile: () => ProfileContextType = () =>
  useContext(ProfileContext);

export function ProfileProvider({
  children,
  profile: user,
}: {
  children: ReactNode;
} & ProfileContextOptions): JSX.Element {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState<OwnUserProfile | null>(user ?? null);

  useEffect(() => {
    async function getUserProfile() {
      const token = await getAccessTokenSilently();
      const user = await getOwnProfile({ token });
      setProfile(user);
      setSentryUser({
        id: user.id,
        email: user.email,
      });
    }

    if (isLoading || !isAuthenticated || user !== undefined) {
      return;
    }

    void getUserProfile();
  }, [isAuthenticated, getAccessTokenSilently, isLoading, user]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export function TestAuth0Provider({
  children,
  profile,
  ...data
}: Partial<Auth0ContextInterface> &
  Partial<Auth0ProviderOptions> &
  ProfileContextOptions): JSX.Element {
  return (
    <Auth0Context.Provider value={data as Auth0ContextInterface}>
      <ProfileProvider profile={profile ?? undefined}>
        {children}
      </ProfileProvider>
    </Auth0Context.Provider>
  );
}

export function createTestToken(
  value: string,
): Auth0ContextInterface["getAccessTokenSilently"] {
  return (() =>
    Promise.resolve(
      value,
    )) as unknown as Auth0ContextInterface["getAccessTokenSilently"];
}
