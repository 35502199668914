import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

export default function BasicUserDeleteDialog(props: {
  onDelete: () => void | Promise<void>;
}): JSX.Element {
  const { onDelete } = props;
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const closeDialog = () => {
    setText("");
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined" color="error">
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="account-delete-dialog-title"
      >
        <DialogTitle id="account-delete-dialog-title">
          Confirm account deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Account deletion is permanent and not reversible. Enter "delete"
            below to confirm
          </DialogContentText>
          <TextField
            data-testid="account-delete-input"
            autoFocus
            margin="dense"
            id="confirmation-text"
            label="Confirm"
            type="text"
            fullWidth
            onChange={(event) =>
              setText(event.target.value ? event.target.value : "")
            }
            value={text}
            variant="standard"
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          {
            <Button
              onClick={onDelete}
              disabled={text !== "delete"}
              variant="outlined"
              color="error"
            >
              Confirm
            </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}
