import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import {
  OwnFileListingResponse,
  FileType,
  FileTypeListingResponse,
  FileListingResponse,
} from "../api";
import GalleryItem from "./GalleryItem";

export default function FileGallery(props: {
  edit: "own-files" | "other-user" | "disabled";
  talentProfile: { id: string | number };
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  files: OwnFileListingResponse | FileListingResponse;
  types: FileTypeListingResponse;
  onUpload?: (file: FileType) => void;
}): JSX.Element {
  const { files, types, edit, onUpload, talentProfile } = props;
  const [inflightUpload, setInflightUpload] = useState<FileType | null>(null);
  return (
    <Container>
      <Grid direction="row" container spacing={3}>
        {types
          .map((t) => {
            const file = files.find((f) => f.type.id === t.id);
            return {
              type: t,
              url: file?.url,
            };
          })
          .map((f) => (
            <Grid item xs key={f.type.id}>
              <GalleryItem
                type={f.type}
                url={f.url}
                talentProfile={talentProfile}
                edit={
                  inflightUpload === null
                    ? edit
                    : f.type.id === inflightUpload
                      ? edit
                      : "disabled"
                }
                onFileUploaded={onUpload}
                onFileUploadStarted={setInflightUpload}
                onFileUploadEnded={() => setInflightUpload(null)}
              />
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
