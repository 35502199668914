import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useProfile } from "../../../auth0";
import { userClaims } from "../../../claims";
import { RestrictedTalentProfileData } from "../api";
import { FileType } from "../file/api";
import { FileListing } from "../file/File";
import Gallery from "../file/Gallery";
import AccountInformation, {
  AccountDataChangeCallback,
} from "./account-information";
import TalentProfileDisplay from "./TalentProfileDisplay";
import TalentProfileForm from "./TalentProfileForm";

function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (value === index) {
      navigate({
        search: new URLSearchParams({
          t: index.toString(),
        }).toString(),
      });
    }
  }, [value, index, navigate]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`talent-profile-tabpanel-${index}`}
      aria-labelledby={`talent-profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function TalentProfileEditTabPanel(props: {
  talentProfile: RestrictedTalentProfileData;
  fileListing: FileListing;
  access: "own-profile" | "other-user";
  mode: "display" | "editor";
  onModeChange: (mode: "display" | "editor") => void;
  onAccountDataChange?: AccountDataChangeCallback;
  onFileUpload: (type: FileType) => void;
}): JSX.Element {
  const {
    talentProfile,
    onModeChange,
    mode,
    access,
    onAccountDataChange,
    fileListing,
    onFileUpload,
  } = props;
  const { profile } = useProfile();
  const elevated =
    profile?.claims.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) ?? false;
  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const index = Number.parseInt(qs.get("t") ?? "");
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number.isNaN(index) ? 0 : index,
  );
  return (
    <div>
      <Container>
        <Tabs
          value={activeTabIndex}
          onChange={(_, newValue) => setActiveTabIndex(newValue as number)}
        >
          <Tab label="Profile" />
          <Tab label="Gallery" />
          {elevated ? <Tab label="Admin" /> : null}
        </Tabs>
      </Container>
      <Fragment>
        <TabPanel value={activeTabIndex} index={0}>
          <Container>
            {mode === "editor" ? (
              <TalentProfileForm
                profile={talentProfile ?? undefined}
                onCancel={() => onModeChange("display")}
                onComplete={() => onModeChange("display")}
              />
            ) : (
              <TalentProfileDisplay
                profile={{ type: "restricted", data: talentProfile }}
                files={fileListing.files}
                onEditClick={() => onModeChange("editor")}
              />
            )}
          </Container>
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <Gallery
            talentProfile={talentProfile}
            listing={fileListing}
            access={access === "own-profile" ? "own-files" : "other-user"}
            onUpload={onFileUpload}
          />
        </TabPanel>
        {elevated ? (
          <TabPanel value={activeTabIndex} index={2}>
            <AccountInformation
              talentProfile={talentProfile}
              elevated={elevated}
              onChange={onAccountDataChange}
            />
          </TabPanel>
        ) : null}
      </Fragment>
    </div>
  );
}
