import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import ApiErrorPanel from "../components/ApiErrorPanel";
import * as Sentry from "@sentry/react";
import { makeStyles } from "tss-react/mui";
import {
  CategoryListingResponse,
  getCategoriesListing,
  getPostListing,
  PostListingResponse,
} from "./api";
import LoadingIndicator from "../components/LoadingIndicator";
import PostListItem from "./components/PostListItem";
import { useAuth0 } from "../auth0";
import AccessDeniedPanel from "../components/AccessDeniedPanel";
import Grid from "@mui/material/Grid";
import ListingPagination, { PageInfo } from "../components/ListingPagination";
import { AxiosError } from "axios";
import {
  ValidationProblemDetails,
  getFirstValidationError,
} from "../api/validation";
import TemporarySnackbar from "../components/TemporarySnackbar";
import { isTimeoutError } from "../api";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  header: {
    marginTop: theme.spacing(1),
  },
  categorySelect: {
    minWidth: 120,
    marginBottom: theme.spacing(2),
  },
  postListing: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(1),
  },
}));

export default function News(): JSX.Element {
  const { classes } = useStyles();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [busy, setBusy] = useState(false);
  const [temporaryError, setTemporaryError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<PageInfo | null>(null);
  const [total, setTotal] = useState(0);
  const [postListingResponse, setPostListingResponse] =
    useState<PostListingResponse | null>(null);
  const [categoriesResponse, setCategoriesResponse] =
    useState<CategoryListingResponse | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const [category, setCategory] = useState(qs.get("category") ?? "");

  const hasAccess = isAuthenticated;

  useEffect(() => {
    async function getPosts() {
      setBusy(true);
      try {
        const token = await getAccessTokenSilently();
        const posts = await getPostListing(
          {
            category: category ? category : undefined,
            page: page?.page,
            limit: page?.size,
          },
          { token },
        );
        setPostListingResponse(posts.data);
        setTotal(posts.totalCount);
      } catch (err) {
        const error = err as AxiosError<ValidationProblemDetails>;
        if (error.response?.status === 400) {
          const validationError = getFirstValidationError(error);
          if (validationError.status === "resolved") {
            setTemporaryError(validationError.message);
            return;
          }
        }

        if (isTimeoutError(err)) {
          setError(
            "News retrieval request failed due to network connectivity issues, please try again",
          );
        } else {
          setError(
            "An error occurred when listing the news articles. Please try again...",
          );
        }

        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to list news articles",
            data: {
              request: error.request as unknown,
              response: error.response,
            },
          }),
        );
      } finally {
        setBusy(false);
      }
    }

    if (!hasAccess || page === null) {
      return;
    }

    void getPosts();
  }, [page, category]);

  useEffect(() => {
    async function getCategories() {
      try {
        const token = await getAccessTokenSilently();
        const categories = await getCategoriesListing({ token });
        setCategoriesResponse(categories);
      } catch (err) {
        const error = err as AxiosError<ValidationProblemDetails>;
        if (error.response?.status === 400) {
          const validationError = getFirstValidationError(error);
          if (validationError.status === "resolved") {
            setTemporaryError(validationError.message);
            return;
          }
        }
        if (isTimeoutError(err)) {
          setTemporaryError(
            "Categories retrieval request failed due to network connectivity issues. Please try again by refreshing the page...",
          );
        } else {
          setTemporaryError(
            "An error occurred when listing the categories. Please try again by refreshing the page...",
          );
        }

        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to retrieve categories",
            data: {
              request: error.request as unknown,
              response: error.response,
            },
          }),
        );
      }
    }

    if (!hasAccess) {
      return;
    }

    void getCategories();
  }, []);

  useEffect(() => {
    if (category !== "") {
      qs.set("category", category);
    } else {
      qs.delete("category");
    }

    navigate({
      search: qs.toString(),
    });
  }, [category, page]);

  if (!hasAccess) {
    return <AccessDeniedPanel />;
  }

  if (error) {
    return <ApiErrorPanel message={error} />;
  }

  const info = postListingResponse ?? [];
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h2" className={classes.header}>
          Latest News
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <FormControl className={classes.categorySelect}>
          <InputLabel id="category-label" variant="standard">
            Category
          </InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category ?? ""}
            data-testid="category-test"
            variant="standard"
            onChange={(event) =>
              setCategory(event.target.value ? event.target.value : "")
            }
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {categoriesResponse?.map((category) => (
              <MenuItem key={category.slug} value={category.slug}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {busy ? <LoadingIndicator backdrop /> : null}
      {postListingResponse !== null && postListingResponse.length === 0 ? (
        <Grid
          item
          container
          className={classes.postListing}
          justifyContent="center"
        >
          <Typography>
            There are no news available right now. Please check again later.
          </Typography>
        </Grid>
      ) : (
        <>
          <Stack
            direction="column"
            justifyContent="center"
            className={classes.postListing}
          >
            {info.map((post, index) => (
              <Grid
                item
                container
                key={post.slug}
                justifyContent="center"
                className={classes.item}
              >
                <PostListItem
                  post={post}
                  background={index % 2 === 0 ? "dark" : "light"}
                />
              </Grid>
            ))}
            <ListingPagination total={total} onPageChange={setPage} />
          </Stack>
        </>
      )}
      <TemporarySnackbar
        message={temporaryError}
        clearMessage={setTemporaryError}
      />
    </Grid>
  );
}
