import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0, useProfile } from "../../auth0";
import * as Sentry from "@sentry/react";
import LoadingIndicator from "../../components/LoadingIndicator";
import ApiErrorPanel from "../../components/ApiErrorPanel";
import TalentProfileEditTabPanel from "./components/TalentProfileEditTabPanel";
import TalentProfileStepperPanel from "./components/TalentProfileStepperPanel";
import {
  FileTypeListingResponse,
  getFileTypes,
  getOwnFiles,
  OwnFileListingResponse,
} from "./file/api";
import { isTimeoutError } from "../../api";
import { useOwnTalentProfile } from "../../OwnTalentProfile";
import extractErrorMessage from "./extract-error";

export default function SelfTalentProfile(): JSX.Element {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { profile } = useProfile();
  const [filesLoading, setFilesLoading] = useState(false);
  const [fileTypesLoading, setFileTypesLoading] = useState(false);
  const [talentProfile, setTalentProfile] = useOwnTalentProfile();
  const [mode, setMode] = useState<"display" | "editor">("display");
  const [files, setFiles] = useState<OwnFileListingResponse | null | undefined>(
    undefined,
  );
  const [fileTypes, setFileTypes] = useState<
    FileTypeListingResponse | null | undefined
  >(undefined);
  const [apiError, setApiError] = useState<string | null>(null);
  const [lastFileUpload, setLastFileUpload] = useState(0);

  const hasAccess = isAuthenticated && profile !== undefined;

  useEffect(() => {
    async function retrieveUserFiles() {
      setFilesLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const data = await getOwnFiles({ token });
        setFiles(data);
      } catch (error) {
        if (isTimeoutError(error)) {
          setApiError(
            "Request to list the files failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          setApiError(
            "Failed to retrieve the talent profile files, please check again later",
          );
        }
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to look up the talent profile files",
            data: {
              user: profile?.id,
            },
          }),
        );
      } finally {
        setFilesLoading(false);
      }
    }

    if (!hasAccess || talentProfile === undefined) {
      return;
    }

    if (talentProfile === null || talentProfile instanceof Error) {
      setFiles(null);
      return;
    }

    void retrieveUserFiles();
  }, [lastFileUpload, talentProfile]);

  useEffect(() => {
    async function retrieveFileTypes() {
      setFileTypesLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const data = await getFileTypes({ token });
        setFileTypes(data);
      } catch (error) {
        if (isTimeoutError(error)) {
          setApiError(
            "Request to list available file types failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          setApiError(
            "Failed to retrieve the available file types, please check again later",
          );
        }
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to look up the available file types",
            data: {
              user: profile?.id,
            },
          }),
        );
      } finally {
        setFileTypesLoading(false);
      }
    }

    if (!hasAccess) {
      return;
    }

    void retrieveFileTypes();
  }, []);

  if (!hasAccess) {
    return <Navigate to="/" />;
  }
  if (talentProfile instanceof Error) {
    return <ApiErrorPanel message={extractErrorMessage(talentProfile)} />;
  }
  if (apiError) {
    return <ApiErrorPanel message={apiError} />;
  }

  const isLoading = fileTypesLoading || filesLoading;
  const isDataDefined =
    talentProfile !== undefined &&
    !(talentProfile instanceof Error) &&
    files !== undefined &&
    fileTypes !== undefined;

  const redirectToPaymentPage =
    !(talentProfile instanceof Error) &&
    talentProfile?.transientState === "pending-payment-redirect";

  if (!isLoading && isDataDefined && !redirectToPaymentPage) {
    if (talentProfile?.isInitialSetupComplete === true) {
      return (
        <TalentProfileEditTabPanel
          mode={mode}
          onModeChange={setMode}
          talentProfile={talentProfile}
          access="own-profile"
          fileListing={{
            files: files ?? null,
            types: fileTypes ?? null,
          }}
          onFileUpload={() => {
            setLastFileUpload((prev) => prev + 1);
          }}
          onAccountDataChange={async (data) => {
            if (!talentProfile) {
              return;
            }
            switch (data.type) {
              case "payment": {
                await setTalentProfile({
                  id: talentProfile.id,
                  paidAt: { value: data.value.paidAt },
                });
                break;
              }
              case "account": {
                // We want to temporarily 'unset' the talent profile to force a data re-fetch
                await setTalentProfile(undefined);
                break;
              }
            }
          }}
        />
      );
    }

    return (
      <TalentProfileStepperPanel
        talentProfile={talentProfile ?? null}
        fileListing={{
          files: files ?? null,
          types: fileTypes ?? null,
        }}
        onFileUpload={() => {
          setLastFileUpload((prev) => prev + 1);
        }}
      />
    );
  }

  return <LoadingIndicator backdrop />;
}
