import Fade from "@mui/material/Fade";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

export default function PageNotFound(): JSX.Element {
  const { classes } = useStyles();
  return (
    <Fade
      in
      unmountOnExit
      style={{
        transitionDelay: `1000ms`,
      }}
      timeout={500}
    >
      <div className={classes.root} data-testid="not-found">
        <Typography variant="h3" component="h1" className={classes.header}>
          Page not found :(
        </Typography>
        <Typography variant="body1">
          The page you requested could not be found
        </Typography>
      </div>
    </Fade>
  );
}
