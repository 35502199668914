import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useAuth0, useProfile } from "../auth0";
import MenuComponent from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { userClaims } from "../claims";
import { Divider } from "@mui/material";
import { FILES_CDN_ADDRESS } from "../cdn";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import Tooltip from "@mui/material/Tooltip";
import AnnouncementIcon from "@mui/icons-material/Announcement";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  toolbar: {
    paddingTop: 3,
    paddingRight: 24, // keep right padding when drawer closed
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Menu(): JSX.Element {
  const { classes } = useStyles();
  const { isAuthenticated, logout } = useAuth0();
  const { profile } = useProfile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    logout();
    handleMenuClose();
  };

  const logo = `${FILES_CDN_ADDRESS}/logo-v2.png`;

  const isElevated =
    profile?.claims?.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) === true;
  const canSeeDetails =
    profile?.claims?.some((c) => userClaims.details.equals(c.type)) === true;

  const isRegularUser = !(isElevated || canSeeDetails);
  const profileLink = isRegularUser
    ? "/talent/profile/self"
    : "/user/profile/self";

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          <Link to="/">
            <img src={logo} alt="Smyth Casting" height={64} />
          </Link>
        </Typography>

        {isAuthenticated && (
          <>
            <Tooltip title="News">
              <IconButton
                aria-label="news"
                component={Link}
                to="/news"
                size="large"
              >
                <AnnouncementIcon />
              </IconButton>
            </Tooltip>
            <IconButton
              data-testid="profile-menu-button"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              size="large"
            >
              <Avatar
                data-testid="avatar"
                alt={profile?.email ?? "N/A"}
                src={profile?.avatarUrl ?? undefined}
              >
                {profile?.email?.[0] ?? profile?.email?.[0]}
              </Avatar>
            </IconButton>
            <MenuComponent
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={anchorEl != null}
              onClose={handleMenuClose}
            >
              {profile !== null ? (
                <MenuItem
                  onClick={handleMenuClose}
                  component={Link}
                  to={profileLink}
                >
                  Profile
                </MenuItem>
              ) : null}
              {canSeeDetails ? (
                <MenuItem
                  color="inherit"
                  to="/talent/profile"
                  onClick={handleMenuClose}
                  component={Link}
                >
                  Talent search
                </MenuItem>
              ) : null}
              {isElevated
                ? [
                    <Divider key="admin-menu-item-divider-top" light />,
                    <MenuItem
                      key="user-listing-menu-item"
                      onClick={handleMenuClose}
                      component={Link}
                      to="/user"
                    >
                      User accounts
                    </MenuItem>,
                    <Divider key="admin-menu-item-divider-bottom" light />,
                  ]
                : null}
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuComponent>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
