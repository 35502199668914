import { makeStyles } from "tss-react/mui";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import RequireAuthentication from "./RequireAuthentication";
import SelfProfile from "./user/profile/SelfProfile";
import UserProfile from "./user/profile/UserProfile";
import { userClaims } from "./claims";
import { useProfile } from "./auth0";
import UserListing from "./user/listing/UserListing";
import ProfilePaymentConfirmation from "./talent/profile/ProfilePaymentConfirmation";
import TalentProfileListing from "./talent/listing/TalentProfileListing";
import React, { useEffect, useMemo } from "react";
import AccountBlocked from "./AccountBlocked";
import PageNotFound from "./components/PageNotFound";
import News from "./news/News";
import Post from "./news/Post";
import Authorize from "./Authorize";
import RenewSubscriptionDialog from "./talent/profile/RenewSubscription";
import { useOwnTalentProfile } from "./OwnTalentProfile";
import { RestrictedTalentProfileData } from "./talent/profile/api";
import LoadingIndicator from "./components/LoadingIndicator";
import { featureToggles } from "./config";
import getExpiration from "./talent/profile/components/TalentProfileExpiration";
import * as Sentry from "@sentry/react";
import SelfTalentProfile from "./talent/profile/SelfTalentProfile";
import OwnTalentProfileEdit from "./talent/profile/TalentProfileEdit";
import TalentProfile from "./talent/profile/TalentProfile";
import { LookupProvider } from "./Lookup";

const useStyles = makeStyles()((theme) => ({
  content: {
    overflow: "auto",
  },
  container: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  placeholder: {
    marginTop: theme.spacing(3),
  },
  box: {
    marginBottom: theme.spacing(5),
  },
}));

function isSubscriptionExpired(
  profile: RestrictedTalentProfileData,
  reference?: Date,
): boolean {
  if (
    profile.unionStatuses.length !== 0 ||
    profile.isInitialSetupComplete === false
  ) {
    return false;
  }

  const expiration = getExpiration(profile.paidAt);

  if (!expiration) {
    return true;
  }

  return (reference ?? new Date()) > expiration;
}

export default function MainPanel(props: { reference?: Date }): JSX.Element {
  const { reference } = props;
  const { profile } = useProfile();
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [ownTalentProfile] = useOwnTalentProfile();
  const routes = useMemo(
    () => ({
      paymentConfirmation: "/talent/profile/self/payment-confirmation",
      renewal: "/talent/profile/renew",
      news: "/news",
      ownTalentProfile: "/talent/profile/self",
    }),
    [],
  );
  const pathname = useMemo(() => location.pathname, [location]);

  const isElevated =
    profile?.claims?.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) === true;
  const withDetails =
    profile?.claims?.some((c) => userClaims.details.equals(c.type)) === true;

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (ownTalentProfile instanceof Error) {
      if (pathname === "/") {
        navigate(routes.news);
        return;
      }
      return;
    }

    const hasClaims = profile.claims.length > 0;
    if (ownTalentProfile === undefined && !hasClaims) {
      return;
    }

    if (pathname === "/") {
      if (hasClaims) {
        navigate(routes.news);
        return;
      }
      if (ownTalentProfile === null) {
        navigate(routes.ownTalentProfile);
        return;
      }
      if (ownTalentProfile) {
        if (ownTalentProfile.isInitialSetupComplete) {
          if (
            isSubscriptionExpired(ownTalentProfile, reference) &&
            featureToggles.nagScreen.enabled &&
            ownTalentProfile.transientState === undefined
          ) {
            Sentry.captureMessage(
              "Redirecting the user to subscription renewal screen",
              (scope) =>
                scope.addBreadcrumb({
                  category: "payment",
                  level: "warning",
                  data: {
                    talentProfile: {
                      id: ownTalentProfile.id,
                      paidAt: ownTalentProfile.paidAt,
                      unionStatuses: ownTalentProfile.unionStatuses,
                    },
                    user: {
                      id: profile.id,
                      email: profile.email,
                    },
                    pathname,
                  },
                }),
            );
            navigate(routes.renewal);
            return;
          } else {
            navigate(routes.news);
            return;
          }
        } else {
          navigate(routes.ownTalentProfile);
          return;
        }
      }
    }

    if (
      pathname === "/news" &&
      !hasClaims &&
      (ownTalentProfile === null ||
        ownTalentProfile?.isInitialSetupComplete === false)
    ) {
      navigate(routes.ownTalentProfile);
      return;
    }

    if (
      ownTalentProfile &&
      !hasClaims &&
      pathname !== routes.paymentConfirmation &&
      isSubscriptionExpired(ownTalentProfile, reference) &&
      featureToggles.nagScreen.enabled &&
      ownTalentProfile.transientState === undefined
    ) {
      Sentry.captureMessage(
        "Redirecting the user to subscription renewal screen",
        (scope) =>
          scope.addBreadcrumb({
            category: "payment",
            level: "warning",
            data: {
              talentProfile: {
                id: ownTalentProfile.id,
                paidAt: ownTalentProfile.paidAt,
                isInitialSetupComplete: ownTalentProfile.isInitialSetupComplete,
                unionStatuses: ownTalentProfile.unionStatuses,
              },
              user: {
                id: profile.id,
                email: profile.email,
              },
              pathname,
            },
          }),
      );
      navigate(routes.renewal);
      return;
    }
  }, [navigate, pathname, profile, ownTalentProfile, routes, reference]);

  return (
    <main className={classes.content}>
      <section className={classes.container}>
        <Routes>
          {profile !== null && withDetails ? (
            <Route
              path={"/talent/profile"}
              element={
                <RequireAuthentication>
                  <TalentProfileListing restricted={isElevated} />
                </RequireAuthentication>
              }
            />
          ) : null}
          <Route
            path="/"
            element={
              <RequireAuthentication>
                <LoadingIndicator message={"Loading content..."} backdrop />
              </RequireAuthentication>
            }
          />
          <Route
            path="/news"
            element={
              <RequireAuthentication>
                <News />
              </RequireAuthentication>
            }
          />
          <Route
            path="/news/:slug"
            element={
              <RequireAuthentication>
                <Post />
              </RequireAuthentication>
            }
          />
          <Route
            path={routes.renewal}
            element={
              <RequireAuthentication>
                <RenewSubscriptionDialog />
              </RequireAuthentication>
            }
          />

          {profile !== null ? (
            <Route
              path="/user/profile/self"
              element={
                <RequireAuthentication>
                  <SelfProfile editable={isElevated} user={profile} />
                </RequireAuthentication>
              }
            />
          ) : null}
          <Route
            path={routes.paymentConfirmation}
            element={
              <RequireAuthentication>
                <ProfilePaymentConfirmation />
              </RequireAuthentication>
            }
          />
          {profile !== null ? (
            <Route
              path={routes.ownTalentProfile}
              element={
                <RequireAuthentication>
                  <LookupProvider>
                    <SelfTalentProfile />
                  </LookupProvider>
                </RequireAuthentication>
              }
            />
          ) : null}
          {profile !== null ? (
            <Route
              path="/talent/profile/self/edit"
              element={
                <RequireAuthentication>
                  <LookupProvider>
                    <OwnTalentProfileEdit />
                  </LookupProvider>
                </RequireAuthentication>
              }
            />
          ) : null}
          {withDetails ? (
            <Route
              path="/talent/profile/:id"
              element={
                <RequireAuthentication>
                  <LookupProvider>
                    <TalentProfile />
                  </LookupProvider>
                </RequireAuthentication>
              }
            />
          ) : null}
          {isElevated ? (
            <Route
              path="/user/:id"
              element={
                <RequireAuthentication>
                  <UserProfile />
                </RequireAuthentication>
              }
            />
          ) : null}
          {isElevated ? (
            <Route
              path="/user"
              element={
                <RequireAuthentication>
                  <UserListing />
                </RequireAuthentication>
              }
            />
          ) : null}
          <Route path="/blocked" element={<AccountBlocked />} />
          <Route path="/authorize" element={<Authorize />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </section>
    </main>
  );
}
