import axios from "axios";
import { RequestOptions, Paged, settings, TOTAL_COUNT_HEADER } from "../api";
import { components, paths } from "../api/schema/v1";
import { tryParseInt } from "../parsing";

const contentType = "application/json; v=1.0";
const accept = "application/json;v=1";

const listingPath: keyof paths = "/news";

export type BlogPost = components["schemas"]["BlogPost"];

export type PostListingRequest =
  paths[typeof listingPath]["get"]["parameters"]["query"];
export type PostListingResponse =
  paths[typeof listingPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getPostListing(
  request: PostListingRequest,
  options: RequestOptions,
): Promise<Paged<PostListingResponse>> {
  const { token } = options;
  const response = await axios.get<PostListingResponse>(
    `${settings.url}${listingPath}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
        Accept: accept,
      },
      params: request,
      timeout: 10_000,
    },
  );

  const totalCountHeader = (
    response.headers as Record<string, string | undefined>
  )[TOTAL_COUNT_HEADER];
  return {
    data: response.data,
    totalCount: tryParseInt(totalCountHeader) ?? 0,
  };
}

const postPath: keyof paths = "/news/{slug}";

export type PostResponse =
  paths[typeof postPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getPost(
  request: {
    slug: string;
  },
  options: RequestOptions,
): Promise<PostResponse> {
  const { token } = options;
  const response = await axios.get<PostResponse>(
    `${settings.url}${postPath.replace("{slug}", request.slug)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}

const categoriesPath: keyof paths = "/news/category";

export type CategoryListingResponse =
  paths[typeof categoriesPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getCategoriesListing(
  options: RequestOptions,
): Promise<CategoryListingResponse> {
  const { token } = options;
  const response = await axios.get<CategoryListingResponse>(
    `${settings.url}${categoriesPath}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}
