import { Grid, Stack } from "@mui/material";
import { ComponentState } from "../../../components/ComponentState";
import { deleteNote, listUserNotes, NoteItem } from "./api";
import { useAuth0, useProfile } from "../../../auth0";
import { AxiosError } from "axios";
import {
  ValidationProblemDetails,
  getFirstValidationError,
} from "../../../api/validation";
import * as Sentry from "@sentry/react";
import { isTimeoutError } from "../../../api";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ListingPagination, {
  PageInfo,
} from "../../../components/ListingPagination";
import { useEffect, useState } from "react";
import NoteListItem from "./components/NoteListItem";
import { NoteCreationPanel } from "./NoteCreationPanel";
import { userClaims } from "../../../claims";

export function NoteListing(props: {
  talentProfileId: number;
}): JSX.Element | null {
  const { talentProfileId } = props;
  const { getAccessTokenSilently, user: adminData } = useAuth0();
  const { profile } = useProfile();
  const [page, setPage] = useState<PageInfo | null>(null);
  const [total, setTotal] = useState(0);
  const [listing, setListing] = useState<NoteItem[]>([]);
  const [lastNoteMutation, setLastNoteMutation] = useState<number | null>(null);
  const [componentState, setComponentState] = useState<ComponentState | null>(
    null,
  );

  const hasAccess =
    profile?.claims?.some((c) => userClaims.details.equals(c.type)) === true;

  const getNotes = async () => {
    setComponentState({ state: "busy" });
    try {
      const token = await getAccessTokenSilently();
      const response = await listUserNotes(
        {
          talentProfileId: talentProfileId,
          limit: page?.size,
          page: page?.page,
        },
        { token },
      );
      setListing(response.data);
      setTotal(response.totalCount);

      setComponentState(null);
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setComponentState({
            state: "error",
            message: validationError.message,
          });
          return;
        }
      }

      if (isTimeoutError(err)) {
        setComponentState({
          state: "error",
          message:
            "Note creation request failed due to network connectivity issues. Please try again.",
        });
      } else {
        setComponentState({
          state: "error",
          message: "An error occurred when creating a note, please try again",
        });
      }

      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to create a talent profile note",
          data: {
            talentProfileId: talentProfileId,
            user: profile?.id,
          },
        }),
      );
    }
  };

  const deleteSelectedNote = async (note: { id: number }) => {
    setComponentState({ state: "busy" });

    try {
      const token = await getAccessTokenSilently();
      const request = {
        id: note.id,
      };
      await deleteNote(request, { token });
      setLastNoteMutation(Date.now());
      setComponentState(null);
    } catch (err) {
      const error = err as AxiosError<ValidationProblemDetails>;
      if (error.response?.status === 400) {
        const validationError = getFirstValidationError(error);
        if (validationError.status === "resolved") {
          setComponentState({
            state: "error",
            message: validationError.message,
          });
          return;
        }
      }
      setComponentState({
        state: "error",
        message:
          "An error occurred when deleting the talent profile note. Please try again.",
      });
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to delete talent profile note",
          data: {
            adminUser: adminData,
            targetUserId: talentProfileId,
            request: error.request as unknown,
            response: error.response,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (!hasAccess || page === null) {
      return;
    }
    void getNotes();
  }, [hasAccess, page, lastNoteMutation]);

  if (!hasAccess) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xl={6} xs>
        <Stack>
          <NoteCreationPanel
            talentProfileId={talentProfileId}
            onCreated={setLastNoteMutation}
          />
          {componentState?.state === "busy" ? <LoadingIndicator /> : null}
          {listing.map((item) => (
            <NoteListItem
              key={item.id}
              item={item}
              onDelete={deleteSelectedNote}
            />
          ))}
          <ListingPagination
            total={total}
            onPageChange={setPage}
            defaultPageSize={10}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
