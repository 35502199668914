import axios, { AxiosError, AxiosResponse } from "axios";
import { RequestOptions, settings } from "../../api";
import { components, paths } from "../../api/schema/v1";

const contentType = "application/json; v=1.0";
const accept = "application/json;v=1";

const creationPath: keyof paths = "/talent/profile/self";

export type TalentProfileCreationRequest =
  paths[typeof creationPath]["post"]["requestBody"]["content"][typeof contentType];
export type TalentProfileCreationResponse =
  paths[typeof creationPath]["post"]["responses"]["200"]["content"][typeof accept];

export async function createTalentProfile(
  request: TalentProfileCreationRequest,
  options: RequestOptions,
): Promise<TalentProfileCreationResponse> {
  const { token } = options;
  const response = await axios.post<
    TalentProfileCreationRequest,
    AxiosResponse<TalentProfileCreationResponse>
  >(`${settings.url}${creationPath}`, request, {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
      Accept: accept,
    },
    timeout: 10_000,
  });

  return response.data;
}

const selfUpdatePath: keyof paths = "/talent/profile/{id}/self";

export type TalentProfileSelfUpdateRequest =
  paths[typeof selfUpdatePath]["patch"]["requestBody"]["content"][typeof contentType];
export type TalentProfileSelfUpdateError =
  paths[typeof selfUpdatePath]["patch"]["responses"]["400"]["content"][typeof accept];

export async function updateOwnTalentProfile(
  id: string | number,
  request: TalentProfileSelfUpdateRequest,
  options: RequestOptions,
): Promise<void> {
  const { token } = options;
  await axios.patch(
    `${settings.url}${selfUpdatePath.replace("{id}", id.toString())}`,
    request,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
}

const updatePath: keyof paths = "/talent/profile/{id}";

export type TalentProfileUpdateRequest =
  paths[typeof updatePath]["patch"]["requestBody"]["content"][typeof contentType];
export type TalentProfileUpdateError =
  paths[typeof updatePath]["patch"]["responses"]["400"]["content"][typeof accept];

export async function updateTalentProfile(
  id: string | number,
  request: TalentProfileUpdateRequest,
  options: RequestOptions,
): Promise<void> {
  const { token } = options;
  await axios.patch(
    `${settings.url}${updatePath.replace("{id}", id.toString())}`,
    request,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
}

const getOwnPath: keyof paths = "/talent/profile/self";
export type TalentProfileData =
  components["schemas"]["TalentProfileInformation"];
export type RestrictedTalentProfileData =
  components["schemas"]["RestrictedTalentProfileInformation"];

export async function getOwnTalentProfile(
  options: RequestOptions,
): Promise<RestrictedTalentProfileData | null> {
  const { token } = options;
  try {
    const response = await axios.get<RestrictedTalentProfileData>(
      `${settings.url}${getOwnPath}`,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
          Accept: accept,
        },
        timeout: 10_000,
      },
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 404) {
      return null;
    }

    throw error;
  }
}

const getProfilePath: keyof paths = "/talent/profile/{id}";
export type SelectedTalentProfileData =
  paths[typeof getProfilePath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getTalentProfile(
  request: { id: string | number },
  options: RequestOptions,
): Promise<SelectedTalentProfileData | null> {
  const { token } = options;
  try {
    const response = await axios.get<TalentProfileData>(
      `${settings.url}${getProfilePath.replace("{id}", request.id.toString())}`,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
          Accept: accept,
        },
        timeout: 10_000,
      },
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function getRestrictedTalentProfile(
  request: { id: string | number },
  options: RequestOptions,
): Promise<RestrictedTalentProfileData | null> {
  const { token } = options;
  try {
    const response = await axios.get<RestrictedTalentProfileData>(
      `${settings.url}${getProfilePath.replace(
        "{id}",
        request.id.toString(),
      )}/restricted`,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${token}`,
          Accept: accept,
        },
        timeout: 10_000,
      },
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 404) {
      return null;
    }

    throw error;
  }
}
