import { AxiosError } from "axios";
import { components } from "./schema/v1";

export type ValidationProblemDetails = components["schemas"]["ValidationProblemDetails"];

export type ValidationError = {
  message: string;
  /**
   * Status of the validation error.
   *
   * `resolved` means that we got a meaningful validation error message out of the response.
   *
   * `default` means we're using a fallback and we didn't exactly get what we expected, and we should probably log it.
   */
  status: "resolved" | "default";
};

export function getFirstValidationError(
  error: AxiosError<ValidationProblemDetails>
): ValidationError {
  const response = error.response;
  const errors = response?.data?.errors;
  if (errors == null) {
    const message =
      (response?.data as { message?: string })?.message ?? error.message;

    return {
      message,
      status: "resolved",
    };
  }
  const firstError = Object.entries(errors)[0];
  if (firstError) {
    const [property, [validationError]] = firstError;
    return {
      message: `${property}: ${validationError}`,
      status: "resolved",
    };
  }

  return {
    message: response?.data?.title ?? "Validation failed",
    status: "default",
  };
}
