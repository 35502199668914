import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useAuth0, useProfile } from "../../auth0";
import { userClaims } from "../../claims";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";
import { getProfileListing, ProfileListItem } from "./api";
import Avatar from "@mui/material/Avatar";
import ActivityBadge from "../../components/ActivityBadge";
import Grid from "@mui/material/Grid";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { tryParseInt } from "../../parsing";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import {
  DEFAULT_PAGE_SIZE,
  isTimeoutError,
  PAGE_SIZE_OPTIONS,
} from "../../api";
import TemporarySnackbar from "../../components/TemporarySnackbar";

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  search: {
    marginBottom: theme.spacing(2),
  },
  error: {
    height: theme.spacing(8),
    color: theme.palette.error.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function UserListing(): JSX.Element {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { profile } = useProfile();
  const [busy, setBusy] = useState<boolean>(false);
  const [query, setQuery] = useState<string>(qs.get("query") ?? "");
  const [listing, setListing] = useState<ProfileListItem[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(tryParseInt(qs.get("page")) ?? 0);
  const [pageSize, setPageSize] = useState(
    tryParseInt(qs.get("limit")) ?? DEFAULT_PAGE_SIZE,
  );
  const [apiError, setApiError] = useState<string | null>(null);
  const hasAccess =
    isAuthenticated &&
    profile?.claims?.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) === true;

  const getSearchResults = async () => {
    setBusy(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await getProfileListing(
        {
          limit: pageSize,
          page: page + 1,
          query,
        },
        {
          token,
        },
      );
      setListing(response.data);
      setTotal(response.totalCount);
      setApiError(null);
    } catch (err) {
      if (isTimeoutError(err)) {
        setApiError(
          "User listing request failed due to network connectivity issues, please try again",
        );
      } else {
        setApiError(
          "Error occurred when listing user profiles. Please try again...",
        );
      }
      const error = err as AxiosError<{ code: string; message: string }>;
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to fetch the user profile listing",
          data: {
            request: error.request as unknown,
            response: error.response,
          },
        }),
      );
    } finally {
      setBusy(false);
    }
  };

  const executeSearch = async () => {
    setPage(0);
    await getSearchResults();
  };

  const changePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!hasAccess) {
      return;
    }

    void getSearchResults();
  }, [hasAccess, page, pageSize]);

  useEffect(() => {
    const queryParams = new URLSearchParams();
    if (query) {
      queryParams.append("query", query);
    } else {
      queryParams.delete("query");
    }

    if (page > 1) {
      queryParams.append("page", page.toString());
    } else {
      queryParams.delete("page");
    }

    queryParams.append("limit", pageSize.toString());
    navigate({ search: queryParams.toString() });
  }, [query, page, pageSize, navigate]);

  if (!hasAccess) {
    return (
      <div className={classes.root} data-testid="access-denied">
        <ErrorOutlineIcon fontSize="large" className={classes.error} />
        <Typography variant="body1">You cannot access this section</Typography>
      </div>
    );
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      <form
        className={classes.search}
        onSubmit={async (event) => {
          event.preventDefault();
          await executeSearch();
        }}
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="search-box">Search</InputLabel>
          <Input
            id="search-box"
            type="text"
            value={query}
            disabled={busy}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="execute search"
                  onClick={executeSearch}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
      {busy ? (
        <LoadingIndicator backdrop={false} />
      ) : (
        <Grid container xl={8}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="user table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Account status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listing.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <Avatar
                            className={classes.avatar}
                            data-testid="avatar"
                            alt={row.email ?? undefined}
                            src={row.avatarUrl ?? undefined}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            <MuiLink
                              component={Link}
                              to={`${location.pathname}/${encodeURIComponent(
                                row.id,
                              )}`.replace("//", "/")}
                            >
                              {row.email}
                            </MuiLink>
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <ActivityBadge isActive={row.isActive} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                    count={total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TemporarySnackbar message={apiError} clearMessage={setApiError} />
        </Grid>
      )}
    </Container>
  );
}
