export default function getExpiration(
  paidAt: string | null | undefined,
): Date | undefined {
  if (!paidAt) {
    return;
  }

  const expiration = new Date(paidAt);
  expiration.setFullYear(expiration.getFullYear() + 1);

  return expiration;
}
