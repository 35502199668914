const url = process.env.REACT_APP_SHOPIFY_SUBSCRIPTION_URL;

if (!url) {
  throw new Error(
    "REACT_APP_SHOPIFY_SUBSCRIPTION_URL environmental variable is missing"
  );
}

export const SHOPIFY_REDIRECT_URL = url;

const tosLink = process.env.REACT_APP_TOS_LINK;

if (!tosLink) {
  throw new Error("REACT_APP_TOS_LINK environmental variable is missing");
}

export const TOS_LINK = tosLink;

type Feature = {
  get enabled(): boolean;
};

const nagScreen: Feature = {
  get enabled() {
    return true;
  },
};

export const featureToggles = {
  nagScreen,
};
