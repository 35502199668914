import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAuth0, useProfile } from "../../auth0";
import { getTalentProfile, getRestrictedTalentProfile } from "./api";
import * as Sentry from "@sentry/react";
import LoadingIndicator from "../../components/LoadingIndicator";
import ApiErrorPanel from "../../components/ApiErrorPanel";
import { userClaims } from "../../claims";
import TalentProfileTabPanel from "./components/TalentProfileTabPanel";
import { TalentProfile as Profile } from "./components/TalentProfile";
import {
  FileListingResponse,
  FileTypeListingResponse,
  getFiles,
  getFileTypes,
} from "./file/api";
import { AxiosError } from "axios";
import { useState } from "react";
import { isTimeoutError } from "../../api";

export default function TalentProfile(props: {
  id?: string | number;
}): JSX.Element {
  const routeParams = useParams<{ id: string | undefined }>();
  const id = props.id ?? routeParams.id;
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { profile } = useProfile();
  const [profileLoading, setProfileLoading] = useState(false);
  const [files, setFiles] = useState<FileListingResponse | null>(null);
  const [fileTypes, setFileTypes] = useState<FileTypeListingResponse | null>(
    null,
  );
  const [talentProfile, setTalentProfile] = useState<
    Profile | null | undefined
  >(undefined);
  const [apiError, setApiError] = useState<string | null>(null);
  const [lastFileUpload, setLastFileUpload] = useState(0);
  const [mode, setMode] = useState<"display" | "editor">("display");

  const hasAccess =
    isAuthenticated &&
    profile?.claims?.some((c) => userClaims.details.equals(c.type)) === true;
  const isElevated =
    isAuthenticated &&
    profile?.claims?.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) === true;

  const shouldRedirect = !hasAccess || !id;

  useEffect(() => {
    async function retrieveUserFiles(id: string | number) {
      try {
        const token = await getAccessTokenSilently();
        const data = await getFiles({ id }, { token });
        setFiles(data);
      } catch (error) {
        if (isTimeoutError(error)) {
          setApiError(
            "Request to list the files failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          const err = error as AxiosError;
          if (err.isAxiosError && err.response?.status === 404) {
            return;
          }
          setApiError(
            "Failed to retrieve the talent profile files, please check again later",
          );
        }
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to look up the talent profile files",
            data: {
              user: profile?.id,
              talentProfile: {
                id,
              },
            },
          }),
        );
      }
    }

    if (!hasAccess || !id || shouldRedirect) {
      return;
    }

    void retrieveUserFiles(id);
  }, [lastFileUpload, id]);

  useEffect(() => {
    async function retrieveFileTypes() {
      try {
        const token = await getAccessTokenSilently();
        const data = await getFileTypes({ token });
        setFileTypes(data);
      } catch (error) {
        if (isTimeoutError(error)) {
          setApiError(
            "Request to list available file types failed due to network connectivity issues, please refresh and try again",
          );
        } else {
          setApiError(
            "Failed to retrieve the available file types, please check again later",
          );
        }
        Sentry.captureException(error, (scope) =>
          scope.addBreadcrumb({
            message: "Failed to look up the available file types",
            data: {
              user: profile?.id,
            },
          }),
        );
      }
    }
    if (!hasAccess || fileTypes !== null || shouldRedirect) {
      return;
    }

    void retrieveFileTypes();
  }, []);

  async function retrieveTalentProfile(id: string | number) {
    setProfileLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (isElevated) {
        const data = await getRestrictedTalentProfile({ id }, { token });
        setTalentProfile(data !== null ? { type: "restricted", data } : null);
      } else {
        const data = await getTalentProfile({ id }, { token });
        setTalentProfile(data !== null ? { type: "regular", data } : null);
      }
    } catch (error) {
      if (isTimeoutError(error)) {
        setApiError(
          "Request to retrieve the talent profile failed due to network connectivity issues, please refresh and try again",
        );
      } else {
        setApiError(
          "Failed to retrieve the talent profile, please check again later",
        );
      }
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Failed to look up the talent profile",
          data: {
            user: profile?.id,
          },
        }),
      );
    } finally {
      setProfileLoading(false);
    }
  }

  useEffect(() => {
    if (shouldRedirect) {
      return;
    }

    if (!id) {
      return;
    }

    void retrieveTalentProfile(id);
  }, [hasAccess, id, mode]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  if (apiError) {
    return <ApiErrorPanel message={apiError} />;
  }

  if (!profileLoading && talentProfile !== undefined) {
    if (talentProfile === null) {
      return <ApiErrorPanel message={"Talent profile was not found"} />;
    }
    return (
      <TalentProfileTabPanel
        talentProfile={talentProfile}
        fileListing={{ files, types: fileTypes }}
        mode={mode}
        onModeChange={setMode}
        onFileUpload={() => {
          setLastFileUpload((prev) => prev + 1);
        }}
        onAccountDataChange={(data) => {
          if (!talentProfile || talentProfile.type !== "restricted") {
            return;
          }
          switch (data.type) {
            case "payment": {
              setTalentProfile({
                ...talentProfile,
                data: {
                  ...talentProfile.data,
                  paidAt: data.value.paidAt,
                },
              });
              break;
            }
            case "account": {
              setTalentProfile({
                ...talentProfile,
                data: {
                  ...talentProfile.data,
                  user: { ...talentProfile.data.user, ...data.value },
                },
              });
              break;
            }
          }
        }}
      />
    );
  }

  return <LoadingIndicator backdrop />;
}
