import axios, { AxiosResponse } from "axios";
import { RequestOptions, settings } from "../../../api";
import { components, paths } from "../../../api/schema/v1";

const contentType = "application/json; v=1.0";
const accept = "application/json;v=1";

export type FileType = components["schemas"]["Identifier"];

const ownUploadUrlGenerationPath: keyof paths = "/talent/profile/self/file/url";
export type OwnSignedUrlRequest =
  paths[typeof ownUploadUrlGenerationPath]["post"]["requestBody"]["content"][typeof contentType];
export type OwnSignedUrlResponse =
  paths[typeof ownUploadUrlGenerationPath]["post"]["responses"]["200"]["content"][typeof accept];

export async function generateOwnSignedUrl(
  request: OwnSignedUrlRequest,
  options: RequestOptions,
): Promise<OwnSignedUrlResponse> {
  const { token } = options;
  const response = await axios.post<
    SignedUrlRequest,
    AxiosResponse<SignedUrlResponse>
  >(`${settings.url}${ownUploadUrlGenerationPath}`, request, {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
      Accept: accept,
    },
    timeout: 10_000,
  });

  return response.data;
}

const uploadUrlGenerationPath: keyof paths = "/talent/profile/{id}/file/url";
export type SignedUrlRequest =
  paths[typeof uploadUrlGenerationPath]["post"]["requestBody"]["content"][typeof contentType];
export type SignedUrlResponse =
  paths[typeof uploadUrlGenerationPath]["post"]["responses"]["200"]["content"][typeof accept];

export async function generateSignedUrl(
  request: SignedUrlRequest & { id: string | number },
  options: RequestOptions,
): Promise<SignedUrlResponse> {
  const { token } = options;
  const { id, ...body } = request;
  const response = await axios.post<
    SignedUrlRequest,
    AxiosResponse<SignedUrlResponse>
  >(
    `${settings.url}${uploadUrlGenerationPath.replace("{id}", id.toString())}`,
    body,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 5000,
    },
  );

  return response.data;
}

const typeListingPath: keyof paths = "/talent/profile/file/type";
export type FileTypeListingResponse =
  paths[typeof typeListingPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getFileTypes(
  options: RequestOptions,
): Promise<FileTypeListingResponse> {
  const { token } = options;
  const response = await axios.get<FileTypeListingResponse>(
    `${settings.url}${typeListingPath}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}

const ownFileListingPath: keyof paths = "/talent/profile/self/file";
export type OwnFileListingResponse =
  paths[typeof ownFileListingPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getOwnFiles(
  options: RequestOptions,
): Promise<OwnFileListingResponse> {
  const { token } = options;
  const response = await axios.get<OwnFileListingResponse>(
    `${settings.url}${ownFileListingPath}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}

const fileListingPath: keyof paths = "/talent/profile/{id}/file";
export type FileListingResponse =
  paths[typeof fileListingPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getFiles(
  profile: { id: string | number },
  options: RequestOptions,
): Promise<FileListingResponse> {
  const { token } = options;
  const response = await axios.get<FileListingResponse>(
    `${settings.url}${fileListingPath.replace("{id}", profile.id.toString())}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}

const talentProfileFileListingPath: keyof paths = "/talent/profile/{id}/file";
export type ProfileFileListingResponse =
  paths[typeof talentProfileFileListingPath]["get"]["responses"]["200"]["content"][typeof accept];

export async function getFilesForProfile(
  request: { profile: { id: string } },
  options: RequestOptions,
): Promise<ProfileFileListingResponse> {
  const { token } = options;
  const response = await axios.get<ProfileFileListingResponse>(
    `${settings.url}${talentProfileFileListingPath.replace(
      "{id}",
      request.profile.id,
    )}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      timeout: 10_000,
    },
  );
  return response.data;
}

export type UploadProgressEvent = {
  loaded: number;
  total: number;
};
export type UploadProgressCallback = (event: UploadProgressEvent) => void;

export async function uploadFile(
  request: {
    file: File;
    url: string;
    contentType?: string;
  },
  onUploadProgress: UploadProgressCallback,
): Promise<void> {
  const { file, url, contentType } = request;
  await axios.put(url, file, {
    headers: {
      "Content-Type": contentType ?? "multipart/form-data",
    },
    onUploadProgress,
  });
}

const ownFileUpdatePath: keyof paths = "/talent/profile/self/file";
export type OwnFileUpdateRequest =
  paths[typeof ownFileUpdatePath]["put"]["requestBody"]["content"][typeof contentType];
export type OwnFileUpdateResponse =
  paths[typeof ownFileUpdatePath]["put"]["responses"]["200"]["content"][typeof accept];

export async function updateOwnFileMetadata(
  request: OwnFileUpdateRequest,
  options: RequestOptions,
): Promise<OwnFileUpdateResponse> {
  const { token } = options;
  const response = await axios.put<
    OwnFileUpdateRequest,
    AxiosResponse<OwnFileUpdateResponse>
  >(`${settings.url}${ownFileUpdatePath}`, request, {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
      Accept: accept,
    },
    timeout: 10_000,
  });
  return response.data;
}

const fileUpdatePath: keyof paths = "/talent/profile/{id}/file";
export type FileUpdateRequest =
  paths[typeof fileUpdatePath]["put"]["requestBody"]["content"][typeof contentType];
export type FileUpdateResponse =
  paths[typeof fileUpdatePath]["put"]["responses"]["200"]["content"][typeof accept];

export async function updateFileMetadata(
  request: FileUpdateRequest & { id: string | number },
  options: RequestOptions,
): Promise<FileUpdateResponse> {
  const { token } = options;
  const { id, ...body } = request;
  const response = await axios.put<
    OwnFileUpdateRequest,
    AxiosResponse<OwnFileUpdateResponse>
  >(`${settings.url}${fileUpdatePath.replace("{id}", id.toString())}`, body, {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
      Accept: accept,
    },
    timeout: 5000,
  });
  return response.data;
}
