import { BrowserOptions } from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const dsn = process.env.REACT_APP_SENTRY_DSN;
const env = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const apiUrl = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_SENTRY_VERSION;

function getTracingOrigins() {
  const tracingOrigins = ["localhost"];
  if (apiUrl !== undefined && !apiUrl.includes("localhost")) {
    // We have a full API URL, but we just want the host for CORS purposes
    tracingOrigins.push(apiUrl.replace("https://", ""));
  }

  return tracingOrigins;
}

function determineTracingSampleRate() {
  switch (env) {
    case "production":
      return 0.1;
    default:
      return 0.33;
  }
}

export const settings: BrowserOptions = {
  dsn: dsn ? dsn : undefined,
  environment: env ? env : "local-development",
  release: version,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: getTracingOrigins(),
    }),
  ],
  tracesSampleRate: determineTracingSampleRate(),
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
};
