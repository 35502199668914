import { AxiosError } from "axios";

if (!process.env.REACT_APP_API_URL) {
  throw new Error("REACT_APP_API_URL environmental variable is missing");
}

export const settings = {
  url: process.env.REACT_APP_API_URL,
};

export type RequestOptions = {
  version?: number;
  token: string;
};

export type UnauthenticatedRequestOptions = {
  version?: number;
};

export type Paged<Data> = {
  data: Data;
  totalCount: number;
};

export const TOTAL_COUNT_HEADER = "total-count";
export const PAGE_SIZE_OPTIONS = [25, 50, 100];
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

export function isTimeoutError(error: unknown): boolean {
  const err = error as AxiosError;
  if (!err.isAxiosError) {
    return false;
  }

  return err.code === "ETIMEDOUT" || err.code === "ECONNABORTED";
}
