import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";

/**
 * Shows an error in a snackbar alert that goes away on its own after a while.
 * @param props Props for the error snackbar. `clearError` function is used to 'clear' the error value in the parent component by setting it to `null`
 */
export default function TemporarySnackbar(props: {
  message: string | null;
  clearMessage(value: null): void;
  severity?: AlertColor | undefined;
}): JSX.Element | null {
  const { message, clearMessage, severity = "error" } = props;
  if (message === null) {
    return null;
  }
  return (
    <Snackbar
      open={true}
      data-testid="temporary-snackbar"
      onClose={() => {
        clearMessage(null);
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={() => {
          clearMessage(null);
        }}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
