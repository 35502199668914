import { makeStyles } from "tss-react/mui";
import ClaimListing from "./ClaimListing";
import { UserProfile } from "../api";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import BasicUserData from "../../components/BasicUserData";
import NewClaim from "./NewClaim";
import { ClaimType } from "../../../claims/api";
import { useState } from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  basicUserData: {
    margin: "auto",
    maxWidth: 500,
  },
  avatar: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(2),
  },
  caption: {
    marginRight: theme.spacing(1),
  },
}));

export default function Profile(props: {
  editable: boolean;
  user: UserProfile;
}): JSX.Element {
  const { classes } = useStyles();
  const { editable, user: profile } = props;

  const [user, setUser] = useState(profile);

  function onClaimDelete(claim: { id: number }) {
    setUser((usr) => ({
      ...usr,
      claims: usr.claims.filter((c) => c.id !== claim.id),
    }));
  }

  function onClaimCreation(claim: ClaimType, id: number, value?: string) {
    setUser((usr) => ({
      ...usr,
      claims: usr.claims.concat({
        id,
        createdAt: new Date().toUTCString(),
        type: claim,
        value,
      }),
    }));
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BasicUserData
            user={user}
            editable={editable}
            className={classes.basicUserData}
          />
        </Grid>
        {user.claims.length > 0 ? (
          <Grid item xs={12}>
            <ClaimListing
              claims={user.claims}
              isEditable={editable}
              onDelete={onClaimDelete}
            />
          </Grid>
        ) : null}
        <Grid item container justifyContent="flex-end">
          {editable ? (
            <NewClaim userId={user.id} onCreate={onClaimCreation} />
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}
