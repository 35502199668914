import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import BlockIcon from "@mui/icons-material/Block";

const useStyles = makeStyles()((theme) => ({
  error: {
    height: theme.spacing(8),
    color: theme.palette.error.main,
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

export default function AccountBlocked(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.root} data-testid="account-blocked">
      <BlockIcon fontSize="large" className={classes.error} />
      <Typography variant="body1">Your account has been blocked</Typography>
      <Typography variant="body2">Please contact support</Typography>
    </div>
  );
}
