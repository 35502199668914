import { makeStyles } from "tss-react/mui";
import { BlogPost } from "../api";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import PostContent from "./PostContent";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  thumbnailImage: {
    width: "100%",
    maxWidth: 750,
  },
  skeletonSpacing: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Post(props: {
  post: BlogPost | null;
  className?: string;
}): JSX.Element {
  const { post, className } = props;
  const { classes, cx } = useStyles();

  if (post === null) {
    return (
      <Fade
        in
        timeout={1000}
        style={{
          transitionDelay: `500ms`,
        }}
        unmountOnExit
      >
        <Container maxWidth="md" data-testid="post-placeholder">
          <Paper className={cx(classes.root, className)}>
            <Skeleton
              variant="rectangular"
              height={140}
              animation="wave"
              className={classes.skeletonSpacing}
            />
            <Skeleton animation="wave" width="80%" />
            <Skeleton
              animation="wave"
              width="80%"
              className={classes.skeletonSpacing}
            />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton
              variant="text"
              width="75%"
              animation="wave"
              className={classes.skeletonSpacing}
            />
            <Skeleton variant="text" animation="wave" />
            <Skeleton
              variant="text"
              width="80%"
              animation="wave"
              className={classes.skeletonSpacing}
            />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton
              variant="text"
              width="80%"
              animation="wave"
              className={classes.skeletonSpacing}
            />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
          </Paper>
        </Container>
      </Fade>
    );
  }

  return (
    <Container>
      <Paper className={cx(classes.root, className)}>
        {post.featuredImageUrl && (
          <Box display="flex" justifyContent="center">
            <img
              className={classes.thumbnailImage}
              src={post.featuredImageUrl}
              alt={post.title}
            />
          </Box>
        )}
        <PostContent post={post} variant="full" />
      </Paper>
    </Container>
  );
}
