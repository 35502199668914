import { Navigate } from "react-router-dom";
import { useAuth0, useProfile } from "../../auth0";
import TalentProfileForm from "./components/TalentProfileForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useOwnTalentProfile } from "../../OwnTalentProfile";
import ApiErrorPanel from "../../components/ApiErrorPanel";
import extractErrorMessage from "./extract-error";

export default function OwnTalentProfileEdit(): JSX.Element {
  const { isAuthenticated } = useAuth0();
  const { profile } = useProfile();
  const [talentProfile] = useOwnTalentProfile();

  const hasAccess = isAuthenticated && profile;

  if (!hasAccess) {
    return <Navigate to="/" />;
  }

  if (talentProfile instanceof Error) {
    return <ApiErrorPanel message={extractErrorMessage(talentProfile)} />;
  }

  if (talentProfile !== undefined) {
    return <TalentProfileForm profile={talentProfile ?? undefined} />;
  }

  return <LoadingIndicator backdrop />;
}
