import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useCallback, useState } from "react";
import {
  ClaimType,
  ClaimTypeListResponse,
  createClaim,
  listAllClaimTypes,
} from "../../../claims/api";
import { useAuth0 } from "../../../auth0";
import LoadingIndicator from "../../../components/LoadingIndicator";
import * as Sentry from "@sentry/react";
import { makeStyles } from "tss-react/mui";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import { AxiosError } from "axios";
import AddIcon from "@mui/icons-material/Add";
import TemporarySnackbar from "../../../components/TemporarySnackbar";

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

export default function NewClaim(props: {
  userId: string;
  onCreate: (claimType: ClaimType, id: number) => void;
}): JSX.Element {
  const { classes } = useStyles();
  const { userId, onCreate } = props;
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [claimTypes, setClaimTypes] = useState<ClaimTypeListResponse>([]);
  const [groupedClaimTypes, setGroupedClaimTypes] = useState<
    Record<string, ClaimType[]>
  >({});
  const [apiError, setApiError] = useState<string | null>(null);
  const [selectedClaimType, setSelectedClaimType] = useState<{
    id: string;
  } | null>(null);

  const callApi = useCallback(async () => {
    setBusy(true);
    try {
      const token = await getAccessTokenSilently();
      const claims = await listAllClaimTypes({ token });
      const grouped = claims.reduce(
        (grouping, type) => {
          const groupId = type.group.id.toString();
          const collection = grouping[groupId];
          if (!collection) {
            grouping[groupId] = [type];
          } else {
            collection.push(type);
          }

          return grouping;
        },
        {} as Record<string, ClaimType[]>,
      );
      setClaimTypes(claims);
      setGroupedClaimTypes(grouped);
    } catch (error) {
      Sentry.captureException(error, (scope) =>
        scope.addBreadcrumb({
          message: "Unexpected failure when listing claim types",
        }),
      );
      setApiError("Failed to list available claim types, please try again");
    } finally {
      setBusy(false);
    }
  }, []);

  const handleClickOpen = async () => {
    setOpen(true);
    await callApi();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = async () => {
    setBusy(true);
    try {
      const token = await getAccessTokenSilently();
      const claimType = Number.parseInt(selectedClaimType?.id ?? "", 10);
      const { id } = await createClaim(
        {
          claimType,
          user: userId,
        },
        {
          token,
        },
      );
      const claim = claimTypes?.find((ct) => ct.id === claimType);
      if (claim) {
        onCreate(claim, id);
      }
    } catch (err) {
      const error = err as AxiosError<{ code: string; message: string }>;
      switch (error.response?.status) {
        case 409:
          setApiError(error.response.data.message);
          break;
        case 400:
          if (error.response?.data.code === "claim.user.missing-reference") {
            setApiError(error.response.data.message);
          } else {
            setApiError("Validation failed");
          }
          break;
        default:
          setApiError("Failed to create a claim for a user");
          break;
      }
    } finally {
      setBusy(false);
      handleClose();
    }
  };

  if (busy) {
    return <LoadingIndicator backdrop={false} />;
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-claim-dialog-title"
      >
        <DialogTitle id="add-claim-dialog-title">
          Add new permission
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="claim-type-select" variant="standard">
              Permission type
            </InputLabel>
            <Select
              id="claim-type-select"
              defaultValue=""
              onChange={(event) => {
                const id = event.target.value;
                setSelectedClaimType({ id });
              }}
              variant="standard"
            >
              {Object.entries(groupedClaimTypes).map(([, group]) => {
                const name = group[0].group.name;
                return [
                  <ListSubheader>{name}</ListSubheader>,
                  ...group.map((entry) => (
                    <MenuItem value={entry.id}>{entry.name}</MenuItem>
                  )),
                ];
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSelect}
            color="primary"
            disabled={selectedClaimType === null}
            data-testid="select-button"
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
      <TemporarySnackbar message={apiError} clearMessage={setApiError} />
    </div>
  );
}
