import axios from "axios";
import { Paged, RequestOptions, settings, TOTAL_COUNT_HEADER } from "../../api";
import { components, paths } from "../../api/schema/v1";
import { tryParseInt } from "../../parsing";

const contentType = "application/json; v=1.0";
const accept = "application/json;v=1";

const listingPath: keyof paths = "/talent/profile";

export type UserInfo = components["schemas"]["BasicUserProfile"];

export type TalentProfileListingRequest =
  paths[typeof listingPath]["get"]["parameters"]["query"];
export type TalentProfileListingResponse =
  paths[typeof listingPath]["get"]["responses"]["200"]["content"][typeof accept];
export type TalentListItem = components["schemas"]["TalentListItem"];

export async function listTalentProfiles(
  request: TalentProfileListingRequest,
  options: RequestOptions,
): Promise<Paged<TalentProfileListingResponse>> {
  const { token } = options;
  const response = await axios.get<TalentProfileListingResponse>(
    `${settings.url}${listingPath}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      params: request,
      timeout: 10_000,
    },
  );

  const totalCountHeader = (
    response.headers as Record<string, string | undefined>
  )[TOTAL_COUNT_HEADER];
  return {
    data: response.data,
    totalCount: tryParseInt(totalCountHeader) ?? 0,
  };
}

const restrictedListingPath: keyof paths = "/talent/profile/restricted";

export type RestrictedTalentProfileListingRequest =
  paths[typeof restrictedListingPath]["get"]["parameters"]["query"];
export type RestrictedTalentProfileListingResponse =
  paths[typeof restrictedListingPath]["get"]["responses"]["200"]["content"][typeof accept];
export type RestrictedTalentListItem =
  components["schemas"]["RestrictedTalentListItem"];

export async function listRestrictedTalentProfiles(
  request: RestrictedTalentProfileListingRequest,
  options: RequestOptions,
): Promise<Paged<RestrictedTalentProfileListingResponse>> {
  const { token } = options;
  const response = await axios.get<RestrictedTalentProfileListingResponse>(
    `${settings.url}${restrictedListingPath}`,
    {
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
        Accept: accept,
      },
      params: request,
      timeout: 10_000,
    },
  );

  const totalCountHeader = (
    response.headers as Record<string, string | undefined>
  )[TOTAL_COUNT_HEADER];
  return {
    data: response.data,
    totalCount: tryParseInt(totalCountHeader) ?? 0,
  };
}

export async function exportRestrictedListingToCsv(
  request: RestrictedTalentProfileListingRequest,
  options: RequestOptions,
): Promise<string> {
  const { token } = options;
  const response = await axios.get<string>(
    `${settings.url}${restrictedListingPath}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "text/csv;v=1",
      },
      params: request,
      responseType: "blob",
      timeout: 10_000,
    },
  );

  return response.data;
}

export type ProfileStatus = components["schemas"]["TalentProfileStatus"];
export type TalentListData = components["schemas"]["TalentListData"];
export type RestrictedTalentListData =
  components["schemas"]["RestrictedTalentListData"];

export type TalentItem =
  | { type: "regular"; data: TalentListData }
  | { type: "restricted"; data: RestrictedTalentListData };
