import { makeStyles } from "tss-react/mui";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";

const useStyles = makeStyles()((theme) => ({
  active: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.success.main,
  },
  inactive: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));

export default function ActivityBadge(props: {
  isActive: boolean;
}): JSX.Element {
  const { classes } = useStyles();
  return (
    <Tooltip title={props.isActive ? "active" : "inactive"}>
      {props.isActive ? (
        <CheckCircleIcon
          className={classes.active}
          aria-label="active account"
          data-testid="account-active-icon"
        />
      ) : (
        <BlockIcon
          className={classes.inactive}
          aria-label="inactive account"
          data-testid="account-inactive-icon"
        />
      )}
    </Tooltip>
  );
}
