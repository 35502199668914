import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import ActivityBadge from "../../components/ActivityBadge";
import React from "react";
import Button from "@mui/material/Button";
import BasicUserDeleteDialog from "./BasicUserDeleteDialog";

export type UserData = {
  id: string;
  email: string;
  isActive: boolean;
  avatarUrl?: string | null;
};

export default function BasicUserDataDisplay(props: {
  className?: string;
  user: UserData;
  onEdit?: () => void;
  onDelete?: () => void;
}): JSX.Element {
  const { user, onEdit, onDelete, className } = props;
  const link = `mailto:${user.email}`;

  return (
    <Paper className={className}>
      <Grid container spacing={2}>
        <Grid
          item
          container
          sm={12}
          xl={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            data-testid="avatar"
            alt={user.email ?? undefined}
            src={user.avatarUrl ?? undefined}
          />
        </Grid>
        <Grid item sm={12} xl={6} container direction="column">
          <Grid item xs container direction="row">
            <Typography gutterBottom variant="subtitle1">
              <Link href={link}>{user.email}</Link>
            </Typography>
          </Grid>
          <Grid item container xs direction="row">
            <Typography>Account status: </Typography>
            <ActivityBadge isActive={user.isActive} />
          </Grid>
        </Grid>
        <Grid item xs container direction="row">
          {onEdit ? (
            <Grid item>
              <Button onClick={onEdit}>Edit</Button>
            </Grid>
          ) : null}
          {onDelete ? (
            <Grid item>
              <BasicUserDeleteDialog onDelete={onDelete} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}
