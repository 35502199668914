import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { FileType } from "../api";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImageDisplayDialog(props: {
  title: string;
  open: boolean;
  url: string;
  onClose: () => void;
}): JSX.Element {
  const { open, url, onClose, title } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="image-display-dialog"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            id="image-display-dialog"
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <img src={url} alt={title} height="100%" />
      </DialogContent>
    </Dialog>
  );
}

export type AssetFileType = { id: FileType; name: string };

export default function UserFileAsset(props: {
  type: AssetFileType;
  error?: string;
  url?: string;
  minimal: boolean;
  progress?: number;
  cardProps?: {
    className?: string;
    variant?: "elevation" | "outlined";
    square?: boolean;
  };
  mediaProps?: {
    className?: string;
  };
  action?: JSX.Element;
}): JSX.Element {
  const { type, url, action, error, progress, mediaProps, cardProps, minimal } =
    props;

  const [displayOpen, setDisplayOpen] = useState(false);

  return (
    <Card {...cardProps}>
      {url !== undefined && type.id !== "video" ? (
        <ImageDisplayDialog
          title={type.name}
          open={displayOpen}
          onClose={() => setDisplayOpen(false)}
          url={url}
        />
      ) : null}
      <CardActionArea
        disabled={url === undefined}
        onClick={() => setDisplayOpen(true)}
      >
        {url !== undefined ? (
          <CardMedia
            component={type.id === "video" ? "video" : "img"}
            controls={true}
            image={url}
            title={type.name}
            className={mediaProps?.className}
          />
        ) : null}
        {minimal ? null : (
          <CardContent>
            <Typography gutterBottom variant="h6" component="p">
              {type.name}
            </Typography>
            {error !== undefined ? (
              <Alert severity="error">{error}</Alert>
            ) : null}
            {type.id === "headshot" && url === undefined ? (
              <Alert severity="warning">Headshot is required</Alert>
            ) : null}
            {progress !== undefined ? (
              <LinearProgress variant="determinate" value={progress} />
            ) : null}
          </CardContent>
        )}
      </CardActionArea>
      {action ? <CardActions>{action}</CardActions> : null}
    </Card>
  );
}
