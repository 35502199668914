import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { TalentProfile } from "./TalentProfile";
import { UserFileCollection } from "../file/File";
import UserFileAsset from "../file/components/UserFileAsset";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { NoteListing } from "../note/NoteListing";
import { useProfile } from "../../../auth0";
import { SHOPIFY_REDIRECT_URL } from "../../../config";
import getExpiration from "./TalentProfileExpiration";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  formLayout: {
    flexGrow: 1,
  },
  headshotCard: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headshot: {
    height: 240,
  },
  dataGrid: {
    padding: theme.spacing(1),
  },
  buttonsRow: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: 2,
  },
  noteListing: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const useLabeledValueStyles = makeStyles()((theme) => ({
  value: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}));

function LabeledValue(props: {
  label: string;
  value: string | JSX.Element[] | null | undefined;
}) {
  const { classes } = useLabeledValueStyles();
  const { label, value } = props;
  return (
    <div>
      <Typography className={classes.label} variant="subtitle2">
        {label}
      </Typography>
      <Typography className={classes.value} variant="body2" component="div">
        {value ?? "—"}
      </Typography>
    </div>
  );
}

export default function TalentProfileDisplay(props: {
  profile: TalentProfile;
  files: UserFileCollection;
  location?: Location;
  reference?: Date;
  onEditClick?: () => void;
}): JSX.Element {
  const {
    profile: talentProfile,
    files,
    location,
    reference,
    onEditClick,
  } = props;
  const headshot = files?.find((f) => f.type.id === "headshot");
  const { classes } = useStyles();
  const { profile } = useProfile();

  const goToPayment = () => {
    (location ?? window.location).replace(SHOPIFY_REDIRECT_URL);
  };

  const shouldRenderRenewButton =
    talentProfile.data.unionStatuses?.length === 0 &&
    profile?.id === talentProfile.data.user.id &&
    talentProfile.type === "restricted";

  const shouldRenderButtonsRow =
    onEditClick !== undefined || shouldRenderRenewButton;

  function getSubscriptionDateCutoff(referenceTimestamp?: Date) {
    const reference = referenceTimestamp
      ? new Date(referenceTimestamp)
      : new Date();
    reference.setDate(reference.getDate() + 30);
    return reference;
  }

  function isRenewEnabled(referenceTimestamp?: Date) {
    const dateOfPaid =
      talentProfile.type === "restricted" ? talentProfile.data.paidAt : null;

    const expiration = getExpiration(dateOfPaid);

    if (!expiration) {
      return true;
    }

    return expiration < getSubscriptionDateCutoff(referenceTimestamp);
  }

  return (
    <>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
      >
        <Box>
          <UserFileAsset
            cardProps={{
              className: classes.headshotCard,
            }}
            mediaProps={{
              className: classes.headshot,
            }}
            type={{ id: "headshot", name: "" }}
            url={headshot?.url ?? `${process.env.PUBLIC_URL}/silhouette.png`}
            minimal
          />
        </Box>
        <Box flexGrow={1}>
          <Paper className={classes.dataGrid}>
            <Grid item container direction="column">
              <Grid container item className={classes.formLayout}>
                <Grid item xs={12} sm={4}>
                  <LabeledValue
                    label="First name"
                    value={talentProfile.data.firstName}
                  />
                  <LabeledValue
                    label="Last name"
                    value={talentProfile.data.lastName}
                  />
                  {talentProfile.type === "restricted" ? (
                    <LabeledValue
                      label="Phone"
                      value={talentProfile.data.phone}
                    />
                  ) : (
                    <div></div>
                  )}
                  <LabeledValue
                    label="Date of birth"
                    value={talentProfile.data.birthDate}
                  />
                  <LabeledValue
                    label="Province of residence"
                    value={talentProfile.data.provinceOfResidence.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledValue
                    label="Gender"
                    value={talentProfile.data.gender.name}
                  />
                  <LabeledValue
                    label="Height (cm)"
                    value={talentProfile.data.height?.toString()}
                  />
                  <LabeledValue
                    label="Weight (lb)"
                    value={talentProfile.data.weight?.toString()}
                  />
                  <LabeledValue
                    label="Ethnicity"
                    value={talentProfile.data.ethnicity.name}
                  />
                  <LabeledValue
                    label="Languages"
                    value={
                      talentProfile.data.languages?.length > 0
                        ? talentProfile.data.languages?.map((l) => (
                            <Chip
                              key={l.id}
                              label={l.name}
                              className={classes.chip}
                            />
                          ))
                        : "—"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledValue
                    label="Hair color"
                    value={talentProfile.data.hairColor.name}
                  />
                  <LabeledValue
                    label="Allergies"
                    value={talentProfile.data.allergies}
                  />
                  <LabeledValue
                    label="Medical conditions"
                    value={talentProfile.data.medicalConditions}
                  />
                  <LabeledValue
                    label="Eye color"
                    value={talentProfile.data.eyeColor.name}
                  />
                  <LabeledValue
                    label="Union Statuses"
                    value={
                      talentProfile.data.unionStatuses?.length > 0
                        ? talentProfile.data.unionStatuses?.map((l) => (
                            <Chip
                              key={l.id}
                              label={l.name}
                              className={classes.chip}
                            />
                          ))
                        : "—"
                    }
                  />
                  {talentProfile.data.unionStatuses?.length === 0 &&
                    talentProfile.type === "restricted" && (
                      <LabeledValue
                        label="Subscription expiration"
                        value={getExpiration(
                          talentProfile.data.paidAt,
                        )?.toLocaleDateString("en-ca")}
                      />
                    )}
                </Grid>
              </Grid>
              {shouldRenderButtonsRow === true ? (
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.buttonsRow}
                >
                  {onEditClick !== undefined ? (
                    <Grid item sm={8}>
                      <Button
                        variant="contained"
                        onClick={onEditClick}
                        color="primary"
                      >
                        Edit
                      </Button>
                    </Grid>
                  ) : (
                    /* Empty Grid included to keep Renew Now button under Subscription expiration timestamp when onEditClick is undefined */
                    <Grid></Grid>
                  )}
                  {shouldRenderRenewButton && (
                    <Grid item sm={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={!isRenewEnabled(reference)}
                        onClick={goToPayment}
                      >
                        Renew Now
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Box>
      </Box>
      <Box className={classes.noteListing}>
        <NoteListing talentProfileId={talentProfile.data.id} />
      </Box>
    </>
  );
}
