import { RequestOptions, settings } from "../api";
import axios, { AxiosResponse } from "axios";
import { paths, components } from "../api/schema/v1";

const removalPath: keyof paths = "/claim/user/{id}";
type ClaimRemovalRequest =
  paths[typeof removalPath]["delete"]["parameters"]["path"];
const accept = "application/json;v=1";
const contentType = "application/json; v=1.0";

export async function removeClaim(
  request: ClaimRemovalRequest,
  options: RequestOptions,
): Promise<void> {
  const { token } = options;
  await axios.delete(
    `${settings.url}${removalPath.replace("{id}", request.id.toString())}`,
    {
      headers: {
        Accept: accept,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );
}

const creationPath: keyof paths = "/claim/user";
type ClaimCreationRequest =
  paths[typeof creationPath]["post"]["requestBody"]["content"][typeof contentType];
type ClaimCreationResponse =
  paths[typeof creationPath]["post"]["responses"]["200"]["content"][typeof accept];

export async function createClaim(
  request: ClaimCreationRequest,
  options: RequestOptions,
): Promise<ClaimCreationResponse> {
  const { token } = options;
  const response = await axios.post<
    ClaimCreationRequest,
    AxiosResponse<ClaimCreationResponse>
  >(`${settings.url}${creationPath}`, request, {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
    },
    timeout: 10_000,
  });

  return response.data;
}

const listPath: keyof paths = "/claim/type";
export type ClaimTypeListResponse =
  paths[typeof listPath]["get"]["responses"]["200"]["content"][typeof accept];
export type ClaimType = components["schemas"]["ClaimType"];

export async function listAllClaimTypes(
  options: RequestOptions,
): Promise<ClaimTypeListResponse> {
  const { token } = options;
  const response = await axios.get<ClaimTypeListResponse>(
    `${settings.url}${listPath}`,
    {
      headers: {
        Accept: accept,
        Authorization: `Bearer ${token}`,
      },
      timeout: 10_000,
    },
  );

  return response.data;
}
