import { useAuth0, useProfile } from "../../../auth0";
import ApiErrorPanel from "../../../components/ApiErrorPanel";
import { FileType } from "./api";
import FileGallery from "./components/FileGallery";
import { FileListing } from "./File";
import { userClaims } from "../../../claims";
import AccessDeniedPanel from "../../../components/AccessDeniedPanel";

export default function Gallery(props: {
  talentProfile?: {
    id: string | number;
  };
  listing: FileListing;
  access: "own-files" | "other-user";
  onUpload?: (type: FileType) => void;
}): JSX.Element {
  const { onUpload, listing, access, talentProfile } = props;
  const { isAuthenticated } = useAuth0();
  const { profile } = useProfile();
  const errorMessage =
    listing.files === null || talentProfile === undefined
      ? "Talent profile could not be found to show a gallery"
      : listing.types === null
        ? "File type listing could not be retrieved to show a gallery"
        : "Gallery is unavailable";

  const hasBasicAccess =
    isAuthenticated &&
    profile &&
    (access === "own-files" ||
      profile?.claims?.some((c) => userClaims.details.equals(c.type)) === true);
  const hasEditPermission =
    access === "own-files" ||
    profile?.claims?.some((c) =>
      userClaims.systemAdministration.equals(c.type),
    ) === true;

  if (!hasBasicAccess) {
    return <AccessDeniedPanel />;
  }

  if (
    listing.files !== null &&
    listing.types !== null &&
    talentProfile !== undefined
  ) {
    return (
      <FileGallery
        files={listing.files}
        types={listing.types}
        talentProfile={talentProfile}
        edit={
          access === "own-files"
            ? "own-files"
            : hasEditPermission
              ? "other-user"
              : "disabled"
        }
        onUpload={hasEditPermission ? onUpload : undefined}
      />
    );
  }

  return <ApiErrorPanel message={errorMessage} />;
}
