import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { makeStyles } from "tss-react/mui";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { TalentItem } from "../api";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import CommentTwoToneIcon from "@mui/icons-material/CommentTwoTone";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 200,
  },
  cover: {
    width: 150,
  },
  indicator: {
    color: theme.palette.error.main,
  },
  note: {
    marginLeft: theme.spacing(0.5),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function determineAge(dateOfBirth: Date, reference?: Date): number {
  const referenceTimestamp = reference ?? new Date();
  const years =
    referenceTimestamp.getUTCFullYear() - dateOfBirth.getUTCFullYear();

  if (
    referenceTimestamp.getUTCMonth() < dateOfBirth.getUTCMonth() ||
    (referenceTimestamp.getUTCMonth() === dateOfBirth.getUTCMonth() &&
      referenceTimestamp.getUTCDate() < dateOfBirth.getUTCDate())
  ) {
    return years - 1;
  }

  return years;
}

export default function TalentListItem(props: {
  item: TalentItem;
  reference?: Date;
}): JSX.Element {
  const { item, reference } = props;
  const { classes } = useStyles();
  const age =
    item.data.profile?.birthDate !== undefined
      ? determineAge(new Date(item.data.profile.birthDate))
      : null;
  const displayName =
    item.data.profile !== undefined
      ? `${item.data.profile.firstName} ${item.data.profile.lastName}`
      : item.type === "restricted"
        ? item.data.user.email
        : "N/A";

  const showNoteIndicator =
    item.type === "restricted" && item.data.profile?.hasNote === true;

  const showPaymentLapsedIndicator = () => {
    if (item.type === "regular") {
      return false;
    }

    const profile = item.data.profile;
    if (profile === undefined) {
      return false;
    }

    if (profile.paidAt === undefined || profile.paidAt === null) {
      return false;
    }

    const paidAt = new Date(profile.paidAt);
    const now = reference ?? new Date();
    const yearBefore = new Date(now.valueOf()).setUTCFullYear(
      now.getUTCFullYear() - 1,
    );
    return paidAt.valueOf() < yearBefore.valueOf();
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={
          item.data.profile?.headshotUrl ??
          `${process.env.PUBLIC_URL}/silhouette.png`
        }
        title={`Headshot of ${displayName}`}
      />
      <CardContent className={classes.content}>
        <Typography variant="subtitle2" gutterBottom noWrap>
          <Box display="flex" alignItems="center">
            {item.data.profile !== undefined ? (
              <MuiLink
                component={Link}
                to={`/talent/profile/${item.data.profile.id}`}
              >
                {displayName}
              </MuiLink>
            ) : (
              displayName
            )}
            {showNoteIndicator && (
              <Tooltip
                title="Note on profile"
                className={classes.note}
                role="tooltip"
                placement="top"
                arrow
              >
                <CommentTwoToneIcon />
              </Tooltip>
            )}
            {showPaymentLapsedIndicator() && (
              <Tooltip
                title="Payment lapsed"
                role="tooltip"
                placement="top"
                arrow
              >
                <MoneyOffIcon className={classes.indicator} />
              </Tooltip>
            )}
          </Box>
        </Typography>
        {age !== null ? (
          <Typography variant="body2" color="textSecondary">
            {age} {age === 1 ? "year" : "years"} old
          </Typography>
        ) : null}
        {item.data.profile !== undefined ? (
          <>
            <Typography variant="body2" color="textSecondary">
              {item.data.profile.height} cm
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item.data.profile.weight} lbs
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item.data.profile.gender.name}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" color="textSecondary">
              Joined{" "}
              {new Date(item.data.user.createdAt).toLocaleDateString(
                undefined,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              —
            </Typography>
            <Typography variant="body2" color="textSecondary">
              —
            </Typography>
            <Typography variant="body2" color="textSecondary">
              —
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}
